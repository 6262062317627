/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthModel } from './_models';
import { refreshAccessToken } from './_requests';

const AUTH_LOCAL_STORAGE_KEY = 'lm-auth';
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json';
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string; 'X-Organization-Id'?: string } }) => {
      const auth = getAuth();
      if (auth && auth.access) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth.access}`;
      }

      const organizationId = localStorage.getItem('lm-organization-id');
      if (organizationId) {
        config.headers['X-Organization-Id'] = organizationId;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async function (error: any) {
      const originalRequest = error.config;

      if (
        (error.response.status == 401 && originalRequest._retry) ||
        originalRequest.url.includes('auth/token/refresh/') // Prevents infinite loop
      ) {
        removeAuth();
        console.error(
          'Request retry is still failing after refreshing token, redirecting to login page...'
        );
        window.location.href = '/auth/login';
        return;
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        console.warn('401 error, trying to refresh token...');

        originalRequest._retry = true;
        const auth = getAuth();

        if (!auth || !auth.refresh) {
          return Promise.reject(error);
        }

        const newToken = await refreshAccessToken(auth.refresh);
        setAuth(newToken.data);

        // We patch axios defaults with the new token
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken.data.access;

        // We patch current request headers with the new token before retrying
        originalRequest.headers['Authorization'] = 'Bearer ' + newToken.data.access;

        return axios.create()(originalRequest);
      }
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
