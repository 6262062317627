import axios from 'axios';
import { Paginated } from '../../../generics/_models';
import { ReleaseFanlink } from '../../catalog/core/_models';
import { v4 as uuidv4 } from 'uuid';
import { PromoLink } from './_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const PROMO_URL = `${API_URL}/api/marketing/promo/`;

export function getPublicPromo(slug: string) {
  // Append a trailing slash to the URL to avoid a 301 redirect
  const finalSlug = slug.endsWith('/') ? slug : slug + '/';
  return axios.get<PromoLink>(PROMO_URL + finalSlug);
}

export function postPromoRating(slug: string, rating: number, fp: string|null = null, comment: string|null = null) {
  const data = { rating, comment, fp };
  
  return axios.post(`${PROMO_URL}${slug}/rating/`, { rating, comment, fp });
}

export function postPromoEvent(promoUID: string, eventType: string, params: any, fp: string|null = null) {
  return axios.post(`${PROMO_URL}${promoUID}/events/`, {
    type: eventType,
    fp: fp,
    params: {
      ...params,
      referrer: document.referrer
    }
  });
}

export function postPromoVisit(promoUID: string, fp: string|null = null) {
  return postPromoEvent(promoUID, 'page_view', {}, fp);
}
