import { useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  fanlinkEmailRegistration,
  getPublicFanlink,
  postFanlinkPlatformClick,
  postFanlinkPlatformPresave,
  postFanlinkSpotifyPresave,
  postFanlinkVisit
} from '../../modules/fanlink/core/_requests';
import { useDocumentTitle } from 'usehooks-ts';
import './fanlink.scss';
import { ReleaseFanlink } from '../../modules/catalog/core/_models';
import { DateTime } from 'luxon';
import { getPlatfomData } from '../../modules/catalog/generics/utils';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { EmailRegistration } from '../../modules/fanlink/core/_models';
import { AxiosResponse } from 'axios';
import Skeleton from 'react-loading-skeleton';
import FanlinkPageSkeleton from './FanlinkSkeleton';
import { OAuthService } from '../../modules/fanlink/core/oauth.service';
import React from 'react';

const EmailRegistrationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required')
});

function useQueryString() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FanlinkPage = function () {
  const params = useParams();
  const location = useLocation();
  const query = useQueryString();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailRegistrationSuccess, setEmailRegistrationSuccess] = useState(false);
  const [isVisitSent, setIsVisitSent] = useState(false);

  const [spotifyPresaved, setSpotifyPresaved] = useState(false);

  // GET FANLINK
  const { data: fanlink } = useQuery(
    ['fanlink', params['*']],
    async () => {
      const { data: fanlink } = await getPublicFanlink(params['*']!);
      return fanlink;
    },
    { refetchOnWindowFocus: false, staleTime: 300 * 1000 }
  );

  useDocumentTitle(fanlink?.title + ' | labelmanager.io' || 'Fanlink | labelmanager.io');

  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    document.body.style.backgroundColor = `#000`;
  }, []);

  // FANLINK VISIT TRACKING
  useEffect(() => {
    if (fanlink && isVisitSent == false) {
      postFanlinkVisit(fanlink.id!).then((response: AxiosResponse) => {});
      setIsVisitSent(true);
    }
  }, [fanlink]);

  // SPOTIFY PRESAVE STATE RESTORE
  useEffect(() => {
    if (fanlink) {
      setSpotifyPresaved(
        localStorage.getItem('fanlink_' + fanlink.id + '_presaved_spotify') !== null
      );
    }
  }, [spotifyPresaved, fanlink]);

  // SPOTIFY PRESAVE CALLBACK
  useEffect(() => {
    if (fanlink) {
      if (query.get('spotify_code')) {
        postFanlinkSpotifyPresave(fanlink.id!, query.get('spotify_code')!).then(
          (response: AxiosResponse) => {
            localStorage.setItem('fanlink_' + fanlink.id + '_presaved_spotify', 'true');
            setSpotifyPresaved(true);
          }
        );
      }
    }
  }, [query, fanlink]);

  const formik = useFormik<EmailRegistration>({
    initialValues: { email: '' },
    validationSchema: EmailRegistrationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // @ts-expect-error - mutate never works
        await fanlinkEmailRegistration(fanlink!.id, values.email);
        setEmailRegistrationSuccess(true);
        setLoading(false);
      } catch (error) {
        console.error(error);
        formik.setStatus(
          'An error occured while updating the fanlink informations. Please try again later.'
        );
        setLoading(false);
      }
    }
  });

  function trackClick(platform: string | null | undefined) {
    if (fanlink && platform) {
      postFanlinkPlatformClick(fanlink.id!, platform).then((response: AxiosResponse) => {});
    }
  }

  function videoURL(videoId: string | null | undefined) {
    if (videoId) {
      return (
        'https://www.youtube.com/embed/' +
        videoId +
        '?autohide=1&autoplay=1&disablekb=1&color=white&showinfo=0&fs=0&controls=2&modestbranding=1&rel=0&mute=1'
      );
    }
    return '';
  }

  function isPresaveEnabled(fanlink: ReleaseFanlink): boolean {
    if (fanlink.presave_enabled == true && fanlink.presave_expiration == null) {
      return true;
    }

    if (
      fanlink.presave_enabled == true &&
      DateTime.now() < DateTime.fromISO(fanlink.presave_expiration!)
    ) {
      return true;
    }
    return false;
  }

  function preSave(platform: string) {
    if (fanlink) {
      postFanlinkPlatformPresave(fanlink.id!, platform).then((response: AxiosResponse) => {});
      const redirectURI = new OAuthService().initOAuthService(platform, location.pathname);
      window.location.href = redirectURI!;
    }
  }

  return (
    <>
      {fanlink && (
        <>
          <div
            className="background"
            style={{
              backgroundImage: 'url("' + fanlink!.cover_url + '")',
              position: 'fixed'
            }}></div>
        </>
      )}
      <div className="background-overlay"></div>

      <div className="content d-flex flex-column flex-column-fluid align-items-center">
        <div className="container">
          <div className="landing-view">
            <div className="blurred-bg"></div>

            {!fanlink ? (
              <>
                <FanlinkPageSkeleton />
              </>
            ) : (
              <div className="container-fluid">
                <div className="row mt-20 landing-artwork">
                  {fanlink?.youtube_enabled == false ||
                  fanlink.youtube_video_id == null ||
                  isPresaveEnabled(fanlink) ? (
                    <>
                      <div className="col-md-12">
                        <img
                          src={fanlink?.cover_url}
                          className="img-responsive"
                          alt={fanlink?.title}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="embed-responsive embed-responsive-16by9 video-wrapper">
                      <iframe
                        className="embed-responsive-item h-100 w-100"
                        frameBorder="0"
                        src={videoURL(fanlink.youtube_video_id)}></iframe>
                    </div>
                  )}
                </div>

                <div className="row mb-20">
                  <div className="col-md-12">
                    <div className="landing-content">
                      <div className="row">
                        <div className="col-md-12 landing-content--text">
                          <h1 className="title">{fanlink?.title}</h1>
                          <h2 className="description">{fanlink?.description}</h2>
                        </div>
                      </div>

                      <div className="row mt-3 landing-row-header">
                        <div className="col-md-12 text-center">
                          <div className="large-triangle"></div>
                          <div className="small-triangle"></div>
                        </div>
                      </div>

                      {isPresaveEnabled(fanlink) && (
                        <div>
                          <div className="row landing-row">
                            <div className="row w-100">
                              <div className="col-sm-7 text-center my-auto">
                                <img
                                  src="/media/landing_providers/spotify_white.svg"
                                  alt=""
                                  className="streaming-platform-logo"
                                />
                              </div>
                              <div className="col-sm-5 text-center my-auto">
                                {spotifyPresaved == false ? (
                                  <button
                                    className="landing-link"
                                    onClick={() => {
                                      preSave('spotify');
                                    }}>
                                    <i className="fa fa-lock"></i> PRE-SAVE
                                  </button>
                                ) : (
                                  <button className="landing-link">
                                    <i className="fa fa-check"></i> SAVED
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="row w-100 mt-3">
                              <div className="col text-center text-white">
                                <p className="presave-warn mb-0">
                                  by presaving this track you hereby accept to follow the artists
                                  and to add this track in your libary once availabe.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {fanlink.platform_links!.length == 0 && !isPresaveEnabled(fanlink) && (
                        <div>
                          <div className="row landing-row">
                            <div className="row w-100">
                              <div className="col-sm-12 text-center my-auto">
                                <p className="font-italic text-white">
                                  This release has actually no links.
                                  <br />
                                  Please come back in a few moment ...
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* {fanlink.platform_links!.length > 0 && !isPresaveEnabled(fanlink) && (
                        <div>
                          {fanlink.platform_links!.map((link) => (
                          <div key={link.streaming_platform.code} className="row landing-row">
                            <div className="row w-100">
                              <div className="col-sm-7 text-center m-auto text-white">
                                <p>Redirecting you to ...</p>
                                <img
                                  src="/assets/landing_providers/{{ streamingPlatformsEnum[redirectService].logoWhite }}"
                                  alt=""
                                  className="streaming-platform-logo"
                                />
                                <p className="mb-0">
                                  <a target="_blank" className="text-white small">
                                    Not working ? Click here
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          ))}
                        </div>
                      )} */}

                      <div>
                        {fanlink.platform_links!.length > 0 && !isPresaveEnabled(fanlink) && (
                          <div>
                            {fanlink.platform_links!.map((link) => {
                              const platformData = getPlatfomData(link.streaming_platform.code!);
                              return (
                                <div
                                  key={link.streaming_platform.code}
                                  className="row landing-row provider">
                                  <div className="col-sm-7 text-center my-auto">
                                    <img
                                      src={'/media/landing_providers/' + platformData?.logoWhite}
                                      alt={platformData?.name}
                                      className="streaming-platform-logo"
                                    />
                                  </div>
                                  <div className="col-sm-5 text-center mt-3 mt-sm-0">
                                    <a
                                      href={link.link}
                                      onClick={() => {
                                        trackClick(link.streaming_platform.code);
                                      }}
                                      className="landing-link"
                                      target="_blank">
                                      <i className={platformData?.actionIcon}></i>
                                      {platformData?.actionText}
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {fanlink.email_registration_enabled && (
                          <div className="row landing-row register-email">
                            {!emailRegistrationSuccess && (
                              <div className="row">
                                <div className="col text-center text-white">
                                  <p>
                                    Want to receive exclusive news from {fanlink?.organization_name}{' '}
                                    before anyone else ?
                                  </p>
                                  <p>
                                    <small>Register your email in the form below</small>
                                  </p>
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col">
                                {!emailRegistrationSuccess && (
                                  <form onSubmit={formik.handleSubmit} noValidate className="form">
                                    <div className="form-group register-email-form">
                                      <div className="input-group">
                                        <input
                                          type="email"
                                          placeholder="my@email.com"
                                          id="email"
                                          className="form-control"
                                          aria-describedby="emailHelpBlock"
                                          {...formik.getFieldProps('email')}
                                        />
                                        <div className="input-group-prepend">
                                          <button
                                            className="btn btn-outline-secondary"
                                            type="submit">
                                            {formik.isSubmitting || loading ? (
                                              <>
                                                <span
                                                  className="spinner-border spinner-border-sm"
                                                  role="status"
                                                  aria-hidden="true"></span>
                                                <span className="sr-only">Loading...</span>
                                              </>
                                            ) : (
                                              <span>Register</span>
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                      {formik.touched.email && formik.errors.email && (
                                        <div className="fv-plugins-message-container mt-2 mb-2">
                                          <div className="fv-help-block">{formik.errors.email}</div>
                                        </div>
                                      )}
                                      <div className="text-muted mt-2 mb-2">
                                        You will receive an email to confirm your subscription. You
                                        can unsubscribe at any time !
                                      </div>
                                    </div>
                                  </form>
                                )}
                                {emailRegistrationSuccess && (
                                  <p className="text-white text-center success-message">
                                    Thank you!
                                    <br />
                                    <br />
                                    You're successfully registered to our newsletter. Please check
                                    your emails to confirm your subscription !
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row fixed-bottom logo-row">
                  <div className="col-md-12">
                    <div className="brand float-right">
                      <a href="https://www.labelmanager.io" target="_blank">
                        <img src="/media/logos/lm/logo-text.png" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FanlinkPage;
