import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getLoginRedirectURL } from './_requests';

export class OAuthService {
  public initOAuthService(type: string, currentURL: string) {
    const state = uuidv4();
    localStorage.setItem(type + '_oauth_state', state);
    localStorage.setItem(type + '_oauth_router_url', currentURL);

    switch (type) {
      case 'spotify':
        return getLoginRedirectURL(state);
    }
  }

  // public onOAuthCallback(type: string, state: string, code: string) {
  //   const originalState = localStorage.getItem(type + '_oauth_state');
  //   const redirectURL = localStorage.getItem(type + '_oauth_router_url');

  //   if (state != originalState) {
  //     return this.router.navigate(['/404']);
  //   }

  //   localStorage.removeItem(type + '_oauth_state');
  //   localStorage.removeItem(type + '_oauth_router_url');

  //   const queryParams = {};
  //   queryParams[type + '_code'] = code;
  //   return this.router.navigate([redirectURL], {
  //     queryParams
  //   });
  // }
}
