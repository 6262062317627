import { Media } from './core/_models';
import { getMedia } from './core/_requests';

export function downloadMediaId(mediaId: string) {
  getMedia(mediaId).then((response) => {
    if (response.status === 200) {
      const url = response.data.download_url;
      window.location.href = url;
    }
  });
}

export function downloadMedia(media: Media) {
  downloadMediaId(media.id!);
}
