import { useParams } from 'react-router-dom';
import './EmailOptin.scss';
import { useEffect, useState } from 'react';
import { confirmOptin } from '../../modules/marketing/core/_requests';

const EmailOptinPage = function () {
  const { optinId } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.backgroundColor = '#121212';
    console.log('EmailOptinPage');
  }, []);

  useEffect(() => {
    confirmOptin(optinId!).finally(() => {
      setLoading(false);
    });
  }, [optinId]);

  return (
    <>
      <div className="email-optin-landing-view">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="landing-content">
                <div className="row">
                  <div className="col-md-12 landing-content--text">
                    {loading ? (
                      <>
                        <h1>Loading...</h1>
                      </>
                    ) : (
                      <>
                        <h1>Congratulations!</h1>
                        <h2>Your subscription is now confirmed!</h2>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row fixed-bottom logo-row">
            <div className="col-md-12 d-none d-md-block">
              <div className="brand float-right">
                <a href="https://www.labelmanager.io" target="_blank">
                  <img src="/media/logos/lm/logo-text.png" />
                </a>
              </div>
            </div>
            <div className="col-md-12 d-xs-block d-md-none">
              <div className="brand float-right">
                <a href="https://www.labelmanager.io" target="_blank">
                  <img src="/media/logos/lm/90w.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailOptinPage;
