import { toast } from 'react-toastify';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { useQuery } from 'react-query';
import { getOrganizationSubscriptionPortalLink } from '../../../modules/organization/core/_requests';

const OrganizationSettingsSubscriptionPage = function () {
  const { currentOrganization } = useOrganization();

  const { data: link, isFetching } = useQuery(
    ['subscriptionPortal', `${currentOrganization?.id}`],
    async () => {
      const response = await getOrganizationSubscriptionPortalLink(`${currentOrganization?.id}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!currentOrganization,
      staleTime: 1000 * 60 * 30
    }
  );

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Subscription</h3>
        </div>
        <div className="card-body border-top p-9">
          <div className="row">
            <div className="col text-center">
              {isFetching || !link ? (
                <>
                  <div className="text-muted">Loading...</div>
                </>
              ) : (
                <a href={link.url} target="_blank" className="btn btn-primary">
                  Go to your subscription portal
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationSettingsSubscriptionPage;
