import axios from 'axios';
import { Track } from './_models';
import { Paginated } from '../../../../generics/_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const TRACKS_URL = `${API_URL}/api/tracks/`;

export function createTrack(releaseId: string, data: Track) {
  return axios.post<Track>(TRACKS_URL, {
    album: releaseId,
    ...data
  });
}

export function getTracks(skip = 0, limit = 25) {
  return axios.get<Paginated<Track>>(TRACKS_URL + `?offset=${skip}&limit=${limit}`);
}

export function getTrack(trackId: string) {
  return axios.get<Track>(TRACKS_URL + `${trackId}/`);
}

export function updateTrack(track: Track) {
  return axios.patch<Track>(TRACKS_URL + `${track.id}/`, track);
}

export function deleteTrack(trackId: string) {
  return axios.delete<Track>(TRACKS_URL + `${trackId}/`);
}
