import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getReleaseTracks } from '../../../../modules/catalog/core/_requests';
import { DisplayArtistName } from '../../../../modules/catalog/artist/DisplayArtistName';

import Skeleton from 'react-loading-skeleton';
import { downloadMediaId } from '../../../../modules/medias/utils';

const ViewReleaseTracksPage = function () {
  const { releaseId } = useParams();

  const { data: releaseTracks, isFetching } = useQuery(
    ['releaseTracks', releaseId],
    async () => {
      const { data: result } = await getReleaseTracks(releaseId!);
      return result;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5,
      enabled: releaseId !== undefined
    }
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Tracks</h3>
        </div>
        <div className="card-body border-top p-9">
          {(releaseTracks && (
            <div className="table-responsive">
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th>#</th>
                    <th className="min-w-150px">Artists</th>
                    <th className="min-w-150px">Name</th>
                    <th className="min-w-150px">ISRC</th>
                    <th className="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {releaseTracks.results.length > 0 ? (
                    <>
                      {releaseTracks.results.map((track, index) => {
                        return (
                          <tr key={track.id}>
                            <td>#{track.track_number}</td>
                            <td>
                              <DisplayArtistName artists={track.artists} />
                            </td>
                            <td>{track.name}</td>
                            <td>{track.isrc}</td>
                            <td className="text-end">
                              {track.audio_master && (
                                <button
                                  className="btn btn-sm btn-dark me-2"
                                  onClick={() => downloadMediaId(`${track.audio_master}`)}>
                                  wav
                                </button>
                              )}
                              {track.audio_mp3 && (
                                <button
                                  className="btn btn-sm btn-dark me-2"
                                  onClick={() => downloadMediaId(`${track.audio_mp3}`)}>
                                  mp3
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr>
                        <td colSpan={4} className="text-center fst-italic">
                          No tracks found
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )) || <Skeleton count={3} />}
        </div>
      </div>
    </>
  );
};

export default ViewReleaseTracksPage;
