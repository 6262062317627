import {
  ReactGoogleAutocompleteInputProps,
  ReactGoogleAutocompleteProps
} from 'react-google-autocomplete';
import { Address } from './_models';

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function capitalize(s: string | undefined) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function googleToLMAddress(place: any) {
  const componentsTranslator = {
    street_number: 'street_number',
    route: 'street',
    country: 'country',
    postal_code: 'zip_code',
    locality: 'city'
  };

  const address: Address = {};
  // Convert properties using the translator
  for (const componentIndex in place.address_components) {
    const component = place.address_components[componentIndex];

    for (const keyIndex in component.types) {
      const key = component.types[keyIndex];

      // @ts-expect-error
      if (componentsTranslator[key]) {
        // @ts-expect-error
        address[componentsTranslator[key]] = component.long_name;
      }
    }
  }

  address['latitude'] = place.geometry.location.lat();
  address['longitude'] = place.geometry.location.lng();

  return address;
}

export function LMAddressToString(address: Address) {
  return `${address.street_number} ${address.street}, ${address.zip_code} ${address.city}, ${address.country}`;
}
