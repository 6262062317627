import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth';
import { useState } from 'react';
import { patchUser } from '../../../modules/user/core/_requests';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';

const passwordResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
});

const initialValues = {
  password: '',
  password_confirmation: ''
};

const AccountSettingsSecurityPage = function () {
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: passwordResetSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data: result } = await patchUser(currentUser!.id.toString(), {
          password: values.password
        });
        setCurrentUser(result);
        setLoading(false);
        formik.resetForm();
        toast.success('Password updated successfully');
      } catch (error) {
        setLoading(false);
        console.error(error);
        formik.setStatus(
          'An error occured while updating your informations. Please try again later.'
        );
      }
    }
  });

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Account Informations</h3>
        </div>
        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    New password :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg">
                      <input
                        type="password"
                        autoComplete="off"
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control form-control-lg',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password
                          }
                        )}
                      />
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Confirm :</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg">
                      <input
                        type="password"
                        autoComplete="off"
                        {...formik.getFieldProps('password_confirmation')}
                        className={clsx(
                          'form-control form-control-lg',
                          {
                            'is-invalid':
                              formik.touched.password_confirmation &&
                              formik.errors.password_confirmation
                          },
                          {
                            'is-valid':
                              formik.touched.password_confirmation &&
                              !formik.errors.password_confirmation
                          }
                        )}
                      />
                    </div>
                    {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.password_confirmation}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col text-center mt-5">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => formik.handleSubmit()}
                  disabled={formik.isSubmitting || !formik.isValid || loading}>
                  {!loading && <span className="indicator-label">Change password</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountSettingsSecurityPage;
