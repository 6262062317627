import axios from 'axios';
import { Paginated } from '../../../generics/_models';
import {
  Artist,
  ArtistSearchResult,
  Release,
  ReleaseFanlink,
  ReleaseFanlinkPlatformLink,
  ReleaseFanlinkStats,
  ReleaseStoreLink
} from './_models';
import { useQuery } from 'react-query';
import { Track } from '../track/core/_models';
import { Media } from '../../medias/core/_models';
import { MarketingPromoType } from '../../marketing/core/_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const GET_RELEASES_URL = `${API_URL}/api/albums/`;
export const FANLINK_URL = `${API_URL}/api/fanlinks/`;

export const ARTIST_URL = `${API_URL}/api/artists/`;
export const SEARCH_ARTIST_URL = `${API_URL}/api/artists/search/`;

export function getReleases(skip = 0, limit = 25) {
  return axios.get<Paginated<Release>>(GET_RELEASES_URL + `?offset=${skip}&limit=${limit}`);
}

export function getRelease(releaseId: string) {
  return axios.get<Release>(GET_RELEASES_URL + `${releaseId}/`);
}

export function getReleaseFanlinks(releaseId: string) {
  return axios.get<Paginated<ReleaseFanlink>>(
    GET_RELEASES_URL + `${releaseId}/fanlinks/?limit=1000`
  );
}

export function getReleaseStoreLinks(releaseId: string) {
  return axios.get<Paginated<ReleaseStoreLink>>(
    GET_RELEASES_URL + `${releaseId}/links/?limit=1000`
  );
}

export function getReleaseTracks(releaseId: string) {
  return axios.get<Paginated<Track>>(GET_RELEASES_URL + `${releaseId}/tracks/?limit=1000`);
}

export function searchArtist(artist: string) {
  return axios.get<ArtistSearchResult[]>(SEARCH_ARTIST_URL + `?q=${artist}`);
}

export function createArtist(name: string) {
  return axios.post<Artist>(ARTIST_URL, { name });
}

export function updateRelease(releaseId: string, data: Release) {
  return axios.patch<Release>(GET_RELEASES_URL + `${releaseId}/`, data);
}

export function updateFanlink(fanlinkId: string, data: ReleaseFanlink) {
  return axios.patch<ReleaseFanlink>(FANLINK_URL + `${fanlinkId}/`, data);
}

export function getFanlinkPlatforms(fanlinkId: string) {
  return axios.get<Paginated<ReleaseFanlinkPlatformLink>>(
    FANLINK_URL + `${fanlinkId}/platforms/?limit=100`
  );
}

export function updateFanlinkPlatforms(fanlinkId: string, data: ReleaseFanlinkPlatformLink[]) {
  return axios.post<Paginated<ReleaseFanlinkPlatformLink>>(
    FANLINK_URL + `${fanlinkId}/platforms/`,
    data
  );
}

export function getFanlinkStats(fanlinkId: string) {
  return axios.get<ReleaseFanlinkStats>(FANLINK_URL + `${fanlinkId}/stats/`);
}

export function createRelease(release: Release) {
  // cleanEmptyStrings(album);
  return axios.post<Release>(GET_RELEASES_URL, release);
}

export function updateReleaseId(releaseId: string, release: Release) {
  // cleanEmptyStrings(album);
  return axios.put<Release>(GET_RELEASES_URL + releaseId + '/', release);
}

export function sendPromo(
  releaseId: string,
  to: [string],
  promoType: MarketingPromoType,
  promoText: string | null = null
) {
  return axios.post(GET_RELEASES_URL + releaseId + '/send_promo/', {
    to,
    type: promoType,
    text: promoText
  });
}

export function sendPromoCampaign(
  releaseId: string,
  subscriberList: string,
  promoType: MarketingPromoType,
  promoText: string | null = null,
  sendAt: string | null = null
) {
  return axios.post(GET_RELEASES_URL + releaseId + '/send_promo_campaign/', {
    subscriber_list_id: subscriberList,
    type: promoType,
    text: promoText,
    send_at: sendAt
  });
}

export function addReleaseMedias(releaseId: string, mediaIds: string[]) {
  return axios.post(GET_RELEASES_URL + releaseId + '/medias/', {
    medias: mediaIds
  });
}

export function getReleaseMedias(releaseId: string) {
  return axios.get<Paginated<Media>>(GET_RELEASES_URL + releaseId + '/medias/');
}
