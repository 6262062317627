import { useIntl } from 'react-intl';
import { PageTitle } from '../../../../_metronic/layout/core';
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  MixedWidget8,
  TablesWidget10,
  TilesWidget1,
  TilesWidget2,
  TilesWidget3,
  TilesWidget4,
  TilesWidget5
} from '../../../../_metronic/partials/widgets';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { Content } from '../../../../_metronic/layout/components/Content';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { LastTracksWidget } from './LastTracksWidget';
import { useQuery } from 'react-query';
import { getReleases } from '../../../modules/catalog/core/_requests';
import { getTracks } from '../../../modules/catalog/track/core/_requests';

const DashboardPage = () => {
  const { data: releases } = useQuery(
    ['releases-5'],
    async () => {
      const { data: releases } = await getReleases(0, 5);
      return releases;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  const { data: tracks } = useQuery(
    ['tracks-1'],
    async () => {
      const { data: tracks } = await getTracks(0, 1);
      return tracks;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  return (
    <>
      <Toolbar />
      <Content>
        <div className="row gx-5 gx-xl-8">
          {/* <div className="col-xl-3">
              <TilesWidget1 className="card-xl-stretch" />
            </div> */}
          <div className="col-xl-3 mb-5 mb-xl-8">
            <TilesWidget5
              className="card-xxl-stretch bg-body"
              svgIcon="disk"
              titleClass="text-gray-900"
              descriptionClass="text-muted"
              iconClass="text-primary"
              title={releases?.count}
              description="releases"
            />
          </div>
          <div className="col-xl-3 mb-5 mb-xl-8">
            <TilesWidget5
              className="card-xxl-stretch bg-body"
              svgIcon="rocket"
              titleClass="text-gray-900"
              descriptionClass="text-muted"
              iconClass="text-primary"
              title={tracks?.count}
              description="tracks"
            />
          </div>
          <div className="col-xl-6 mb-5 mb-xl-8">
            <div className={clsx('card h-150px')}>
              <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                <div className="me-2">
                  <h2 className="fw-bold text-gray-800 mb-3">Keep your catalog up to date</h2>
                  <div className="text-muted fw-semibold fs-6">
                    Create your upcoming releases now
                  </div>
                </div>
                <Link to="/manager/catalog/release/create" className="btn btn-primary fw-semibold">
                  Create a release
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-5 g-xl-8">
          <div className="col-xl-6">
            <LastTracksWidget className="card-xl-stretch mb-xl-8" />
          </div>
        </div>

        {/* <div className="row g-5 g-xl-8">
        <div className="col-xl-4">
          <MixedWidget8
            className="card-xxl-stretch mb-xl-3"
            chartColor="success"
            chartHeight="150px"
          />
        </div>

        <div className="col-xl-8">
          <div className="row gx-5 gx-xl-8 mb-5 mb-xl-8">
            <div className="col-xl-6">
              <TilesWidget2 className="card-xl-stretch mb-5 mb-xl-8" />
            </div>
            <div className="col-xl-6">
              <TilesWidget3 className="card-xl-stretch mb-5 mb-xl-8" />
            </div>
          </div>
        </div>
      </div>

      <div className="row gy-5 g-xl-8">
        <div className="col-xxl-4">
          <MixedWidget2
            className="card-xl-stretch mb-xl-8"
            chartColor="danger"
            chartHeight="200px"
            strokeColor="#cb1e46"
          />
        </div>
        <div className="col-xxl-4">
          <ListsWidget5 className="card-xxl-stretch" />
        </div>
        <div className="col-xxl-4">
          <MixedWidget10
            className="card-xxl-stretch-50 mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="150px"
          />
          <MixedWidget11
            className="card-xxl-stretch-50 mb-5 mb-xl-8"
            chartColor="primary"
            chartHeight="175px"
          />
        </div>
      </div>

      <div className="row gy-5 gx-xl-8">
        <div className="col-xxl-4">
          <ListsWidget3 className="card-xxl-stretch mb-xl-3" />
        </div>
        <div className="col-xl-8">
          <TablesWidget10 className="card-xxl-stretch mb-5 mb-xl-8" />
        </div>
      </div>

      <div className="row gy-5 g-xl-8">
        <div className="col-xl-4">
          <ListsWidget2 className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-4">
          <ListsWidget6 className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-4">
          <ListsWidget4 className="card-xl-stretch mb-5 mb-xl-8" items={5} />
        </div>
      </div> */}
      </Content>
    </>
  );
};

const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
