import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { Languages } from './Languages';
import { toAbsoluteUrl } from '../../../helpers';
import { useQuery } from 'react-query';
import { getOrganizations } from '../../../../app/modules/organization/core/_requests';
import { Organization } from '../../../../app/modules/organization/core/_models';
import { useOrganization } from '../../../../app/modules/organization/core/Organization';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const { currentOrganization, setCurrentOrganization } = useOrganization();

  const { data: organizations } = useQuery(
    ['organizations'],
    async () => {
      const { data: organizations } = await getOrganizations();
      return organizations;
    },
    { refetchOnWindowFocus: false, staleTime: 300 * 1000 }
  );

  const chooseOrganization = (organization: Organization) => {
    setCurrentOrganization(organization);
    window.localStorage.setItem('lm-organization-id', organization.id?.toString() || '');
    window.location.href = '/';
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
      data-kt-menu="true">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {/* <img alt='Logo' src={toAbsoluteUrl('media/avatars/300-2.jpg')} /> */}
            {currentUser ? (
              <div className="symbol-label fs-2 fw-bold text-success">
                {currentUser?.first_name[0] + currentUser?.last_name[0]}
              </div>
            ) : (
              <div className="symbol-label fs-2 fw-bold text-success">?</div>
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bold d-flex align-items-center fs-5">
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <a href="#" className="fw-semibold text-muted text-hover-primary fs-7">
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="menu-item px-5 text-center">
        <span className="badge badge-secondary">{currentOrganization?.name}</span>
      </div>

      <div className="separator my-2"></div>

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
          </span>
        </a>
      </div> */}

      {organizations && organizations.results.length > 1 && (
        <>
          <div
            className="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement="left-start"
            data-kt-menu-flip="bottom">
            <a href="#" className="menu-link px-5">
              <span className="menu-title">Switch organization</span>
              <span className="menu-arrow"></span>
            </a>

            <div className="menu-sub menu-sub-dropdown w-175px py-4">
              {organizations &&
                organizations.results.map((organization: Organization) => (
                  <div className="menu-item px-3" key={organization.id}>
                    <a onClick={() => chooseOrganization(organization)} className="menu-link px-5">
                      {organization.name}
                    </a>
                  </div>
                ))}
            </div>
          </div>
          <div className="separator my-2"></div>
        </>
      )}

      {/* <div className="menu-item px-5">
        <Link to={'/crafted/pages/profile'} className="menu-link px-5">
          My Profile
        </Link>
      </div> */}

      {/* <div className="menu-item px-5">
        <a href="#" className="menu-link px-5">
          My Statements
        </a>
      </div> */}

      {/* <div className="separator my-2"></div> */}

      {/* <Languages /> */}

      <div className="menu-item px-5 my-1">
        <Link to="/manager/account/informations" className="menu-link px-5">
          Personal Settings
        </Link>
      </div>
      <div className="menu-item px-5 my-1">
        <Link to="/manager/organization/informations" className="menu-link px-5">
          Organization Settings
        </Link>
      </div>

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
