import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { getRelease, updateRelease } from '../../../../modules/catalog/core/_requests';
import { Artist, Release } from '../../../../modules/catalog/core/_models';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { DateTime } from 'luxon';
import { useCallback, useDebugValue, useEffect, useState } from 'react';
import { KTIcon } from '../../../../../_metronic/helpers';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragDropArtistList from '../../../../modules/catalog/artist/DragDropArtistList';
import { ArtistTypeahead } from '../../../../modules/catalog/artist/ArtistTypeahead';
import { removeEmpty } from '../../../../modules/catalog/generics/utils';

const ReleaseUpdateSchema = Yup.object().shape({});

const ViewReleaseInformationsPage = function () {
  const { releaseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [releaseArtists, setReleaseArtists] = useState<Artist[]>([]);

  const queryClient = useQueryClient();

  const releaseUpdateMutation = useMutation({
    mutationFn: async (updatedReleaseData: Release) => {
      const { data: release } = await updateRelease(updatedReleaseData.id!, updatedReleaseData!);
      return release;
    },
    onSuccess: (data) => {
      console.info('Patching release with data: ', data);
      queryClient.setQueryData(['release', `${data.id}`], data);
      setLoading(false);
      formik.setStatus(null);
    }
  });

  const {
    data: release,
    isFetching,
    refetch
  } = useQuery(
    ['release', releaseId],
    async () => {
      const result = await getRelease(releaseId!);
      return result.data;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5
    }
  );

  useEffect(() => {
    if (release) {
      setReleaseArtists(release.artists || []);
    }
  }, [release]);

  const formik = useFormik<Release>({
    initialValues: {
      name: release?.name || '',
      upc: release?.upc || '',
      catalog_number: release?.catalog_number || '',
      release_date: release?.release_date || ''
    },
    validationSchema: ReleaseUpdateSchema,
    onSubmit: async (values) => {
      if (!release) {
        return;
      }

      setLoading(true);
      try {
        values = removeEmpty(values);
        await releaseUpdateMutation.mutateAsync({
          ...values,
          artists: releaseArtists,
          id: release.id!
        });
      } catch (error) {
        console.error(error);
        formik.setStatus(
          'An error occured while updating the release informations. Please try again later.'
        );
        setLoading(false);
      }
    }
  });

  const addArtist = useCallback(
    (artist: Artist) => {
      setReleaseArtists([...releaseArtists, artist]);
    },
    [releaseArtists, setReleaseArtists]
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Release Informations</h3>
        </div>
        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col">
                <h3>General informations:</h3>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Title:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Middle"
                        {...formik.getFieldProps('name')}
                      />
                    </div>
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">UPC:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="1234567891"
                        {...formik.getFieldProps('upc')}
                      />
                    </div>
                    {formik.touched.upc && formik.errors.upc && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.upc}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Catalog number:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="CATALOG001"
                        {...formik.getFieldProps('catalog_number')}
                      />
                    </div>
                    {formik.touched.catalog_number && formik.errors.catalog_number && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.catalog_number}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Release date:
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        readOnly={true}
                        {...formik.getFieldProps('release_date')}
                      />
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() => {
                          setShowDatePicker(!showDatePicker);
                        }}>
                        Edit
                      </button>
                    </div>
                    {showDatePicker && (
                      <DayPicker
                        ISOWeek
                        mode="single"
                        selected={DateTime.fromISO(formik.values.release_date!).toJSDate()}
                        defaultMonth={DateTime.fromISO(formik.values.release_date!).toJSDate()}
                        onSelect={(date) => {
                          formik.setFieldValue(
                            'release_date',
                            DateTime.fromJSDate(date!).toISODate()
                          );
                          setShowDatePicker(false);
                        }}
                      />
                    )}
                    {formik.touched.release_date && formik.errors.release_date && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.release_date}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                {release && release.artists && (
                  <>
                    <div className="row mt-4">
                      <div className="col">
                        <h3>Artists</h3>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col">
                        <p className="mb-2 fw-bolder">Search artist to add one:</p>
                        <ArtistTypeahead addArtist={addArtist} />
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col">
                        <p className="mb-2 fw-bolder">Current artist list:</p>
                        <DragDropArtistList
                          artists={releaseArtists}
                          setArtists={setReleaseArtists}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="row mt-10">
              <div className="col text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting || !formik.isValid || loading}>
                  {!loading && <span className="indicator-label">Update release informations</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ViewReleaseInformationsPage;
