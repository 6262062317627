import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth';
import { useState } from 'react';
import { patchUser } from '../../../modules/user/core/_requests';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
  getInvoicePDFURL,
  getInvoiceURL,
  getOrganization,
  getOrganizationInvoices
} from '../../../modules/organization/core/_requests';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';

const UserUpdateSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required')
});

const OrganizationSettingsInvoicesPage = function () {
  const { currentOrganization } = useOrganization();

  const { data: invoices, isLoading } = useQuery(
    ['invoices', `${currentOrganization?.id}`],
    async () => {
      const response = await getOrganizationInvoices(`${currentOrganization?.id}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!currentOrganization,
      staleTime: 1000 * 60 * 5
    }
  );

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Invoices</h3>
        </div>
        <div className="card-body border-top p-9">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Payment status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {invoices && invoices.results.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No invoices
                        </td>
                      </tr>
                    )}
                    {invoices &&
                      invoices.results.length > 0 &&
                      invoices.results?.map((invoice) => (
                        <tr key={invoice.id}>
                          <td>{invoice.reference}</td>
                          <td>{DateTime.fromISO(invoice.created).toLocaleString()}</td>
                          <td>{invoice.amount} &euro;</td>
                          <td>
                            {invoice.payed ? (
                              <span className="badge badge-success">Paid</span>
                            ) : (
                              <span className="badge badge-danger">Not Paid</span>
                            )}
                          </td>
                          <td>
                            <a
                              onClick={async () => {
                                const res = await getInvoiceURL(`${invoice.id}`);
                                window.open(res.data.url, '_blank');
                              }}
                              target="_blank"
                              className="btn btn-primary btn-sm btn-icon me-3">
                              <i className="fas fa-eye"></i>
                            </a>
                            <a
                              onClick={async () => {
                                const res = await getInvoicePDFURL(`${invoice.id}`);
                                window.open(res.data.url, '_blank');
                              }}
                              target="_blank"
                              className="btn btn-primary btn-sm btn-icon">
                              <i className="fas fa-file-pdf"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationSettingsInvoicesPage;
