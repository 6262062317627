import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingReleaseCard = () => {
  return (
    <>
      <div className="col-md-6 col-sm-12 col-xl-3">
        <div className="card mb-5">
          <div className="card-body">
            <div className="row d-flex mb-5">
              <div className="col">
                <Skeleton className="h-90px w-90px" />
              </div>
              <div className="col">
                <Skeleton />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col">
                <Skeleton />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <Skeleton />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <Skeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { LoadingReleaseCard };
