import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { Artist, Release } from '../../../../modules/catalog/core/_models';
import { ArtistTypeahead } from '../../../../modules/catalog/artist/ArtistTypeahead';
import DragDropArtistList from '../../../../modules/catalog/artist/DragDropArtistList';
import Flatpickr from 'react-flatpickr';
import { DateTime } from 'luxon';
import { Dropzone } from '../../../../modules/medias/Dropzone';
import { Media } from '../../../../modules/medias/core/_models';
import { createRelease, updateReleaseId } from '../../../../modules/catalog/core/_requests';
import { removeEmpty } from '../../../../modules/catalog/generics/utils';

const ReleaseCreateSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  upc: Yup.string().matches(/^[0-9]+$/, 'UPC must be only digits'),
  catalog_number: Yup.string(),
  release_date: Yup.string().required('Release date is required'),
  artwork_id: Yup.string().required('Artwork is required')
});

const CreateReleaseStep1Page = function () {
  const { releaseId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [releaseArtists, setReleaseArtists] = useState<Artist[]>([]);

  const release: Release = {};

  const addArtist = useCallback(
    (artist: Artist) => {
      setReleaseArtists([...releaseArtists, artist]);
    },
    [releaseArtists, setReleaseArtists]
  );

  const formik = useFormik<Release>({
    initialValues: {
      name: release?.id || '',
      upc: release?.upc || '',
      release_date: release?.release_date || '',
      artwork_id: release?.artwork_id || '',
      catalog_number: release?.catalog_number || ''
    },
    validationSchema: ReleaseCreateSchema,
    onSubmit: async (values) => {
      if (formik.isValid) {
        setLoading(true);
        try {
          // We first create the release without artists (as they can't be added on non-existing object)
          const { data: result } = await createRelease(removeEmpty(values));

          // Then we update the release with the artists
          await updateReleaseId(result.id!, { artists: releaseArtists });

          navigate(`/manager/catalog/release/create/${result.id}/tracks`);
        } catch (error) {
          console.error(error);
          formik.setStatus(
            "We're sorry, something went wrong while creating your release. Please try again. Our team has been notified."
          );
        } finally {
          setLoading(false);
        }
      } else {
        formik.setStatus('There is an issue with your release data. Please check and try again.');
      }
    }
  });

  function addMedia(media: Media) {
    formik.setFieldValue('artwork_id', media.id);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className="row mt-2">
          <div className="col">
            <h3>General informations:</h3>
          </div>
        </div>

        {formik.status && (
          <div className="row">
            <div className="col">
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col">
            <div className="row mb-2">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">Title:</label>
              <div className="col-lg-8">
                <div className="input-group input-group-lg input-group-solid">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Middle"
                    {...formik.getFieldProps('name')}
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row mb-2">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">Release date:</label>
              <div className="col-lg-8">
                <div className="input-group input-group-lg input-group-solid">
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <Flatpickr
                      value={formik.values.release_date}
                      onChange={([date1]: Date[]) => {
                        formik.setFieldValue(
                          'release_date',
                          DateTime.fromJSDate(date1).toISODate()
                        );
                      }}
                      className="form-control"
                      placeholder="Release date"
                    />
                  </div>
                </div>
                {formik.touched.release_date && formik.errors.release_date && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.release_date}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row mb-2">
              <label className="col-lg-4 col-form-label fw-bold fs-6">Release UPC code :</label>
              <div className="col-lg-8">
                <div className="input-group input-group-lg input-group-solid">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="123456789098"
                    {...formik.getFieldProps('upc')}
                  />
                </div>
                {formik.touched.upc && formik.errors.upc && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.upc}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row mb-2">
              <label className="col-lg-4 col-form-label fw-bold fs-6">Catalog number :</label>
              <div className="col-lg-8">
                <div className="input-group input-group-lg input-group-solid">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="LBLMNGR001"
                    {...formik.getFieldProps('catalog_number')}
                  />
                </div>
                {formik.touched.catalog_number && formik.errors.catalog_number && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.catalog_number}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col">
            <h3>Artists :</h3>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <p className="mb-2 fw-bolder">Search artist to add one:</p>
            <ArtistTypeahead addArtist={addArtist} />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <p className="mb-2 fw-bolder">Current artist list:</p>
            <DragDropArtistList artists={releaseArtists} setArtists={setReleaseArtists} />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col">
            <h3>Artwork :</h3>
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col h-400px">
            <Dropzone
              addMedia={addMedia}
              restrictions={{
                maxNumberOfFiles: 1,
                allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif']
              }}
              public={true}
            />
            {formik.touched.artwork_id && formik.errors.artwork_id && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.artwork_id}</div>
              </div>
            )}
          </div>
        </div>

        <div className="row mt-10 mb-5">
          <div className="col text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formik.isSubmitting || !formik.isValid || loading}>
              {!loading && <span className="indicator-label">Continue</span>}
              {loading && (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateReleaseStep1Page;
