import { DateTime } from 'luxon';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { Toolbar } from '../../../../../_metronic/layout/components/toolbar/Toolbar';
import { Content } from '../../../../../_metronic/layout/components/Content';
import { Outlet, useLocation, useParams } from 'react-router-dom';

const CreateReleaseWrapper = function () {
  const { releaseId } = useParams();
  const location = useLocation();

  return (
    <>
      <PageTitle breadcrumbs={[]}>Release Catalog</PageTitle>
      <Toolbar />
      <Content>
        <div className="card mb-5 mb-xl-10">
          <div className="card-header">
            <h3 className="card-title">Create a new release</h3>
          </div>
          <div className="card-body pt-9">
            <div className="stepper stepper-pills" id="kt_stepper_example_clickable">
              <div className="stepper-nav flex-center flex-wrap mb-10">
                <div
                  className={
                    'stepper-item mx-8 my-4' +
                    (location.pathname.includes('tracks') ? ' completed' : '')
                  }
                  data-kt-stepper-element="nav"
                  data-kt-stepper-action="step">
                  <div className="stepper-wrapper d-flex align-items-center">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Step 1</h3>

                      <div className="stepper-desc">Basic informations</div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px"></div>
                </div>

                <div
                  className={
                    'stepper-item mx-8 my-4' +
                    (location.pathname.includes('tracks') ? ' current' : '')
                  }
                  data-kt-stepper-element="nav"
                  data-kt-stepper-action="step">
                  <div className="stepper-wrapper d-flex align-items-center">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Step 2</h3>

                      <div className="stepper-desc">Release tracks</div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px"></div>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default CreateReleaseWrapper;
