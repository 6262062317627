import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { useEffect, useRef, useState } from 'react';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { Dashboard } from '@uppy/react';
import { Media, UploadMedia } from './core/_models';
import { firstValueFrom, from, mergeMap, of } from 'rxjs';
import { AxiosResponse } from 'axios';
import { confirmMediaUpload, createMedia } from './core/_requests';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './uppy.scss';

function Dropzone(props: {
  addMedia: (media: Media) => void;
  restrictions: object;
  public: boolean;
  onComplete?: () => void;
}) {
  const [isPublic, setPublic] = useState<boolean>(props.public);

  const [uppy] = useState(() => {
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: props.restrictions,
      debug: true
    });

    uppy.use(AwsS3, {
      // @ts-expect-error - Uppy typings are not up to date
      getUploadParameters: (file) => {
        return firstValueFrom(
          from(createMedia(file.name, file.type, isPublic)).pipe(
            mergeMap((media: AxiosResponse<UploadMedia>) => {
              file.meta['media_id'] = media.data.media.id;
              return of({
                method: 'POST',
                url: media.data.upload_url.url,
                fields: media.data.upload_url.fields,
                headers: {}
              });
            })
          )
        );
      }
    });

    uppy.on('upload-success', (result: object | undefined) => {
      if (result) {
        // @ts-expect-error - Uppy typings are not up to date
        confirmMediaUpload(result.meta['media_id']).then((res) => {
          props.addMedia(res.data);
        });
      }
    });

    uppy.on('complete', () => {
      if (props.onComplete) {
        props.onComplete();
      }
    });

    return uppy;
  });

  useEffect(() => {
    uppy.setOptions({ restrictions: props.restrictions });
  }, [props.restrictions]);

  return (
    <Dashboard
      uppy={uppy}
      width="100%"
      height="100%"
      note="Drop your files here"
      hideRetryButton={true}
      singleFileFullScreen={true}
      hideCancelButton={true}
      hidePauseResumeButton={true}
      hideUploadButton={true}
      doneButtonHandler={() => {
        console.log('done');
      }}
    />
  );
}

export { Dropzone };
