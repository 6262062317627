import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { WithChildren } from '../../../../_metronic/helpers';

type FingerprintContextProps = {
  currentFingerprint: string | undefined;
  setCurrentFingerprint: Dispatch<SetStateAction<string | undefined>>;
};

const initFingerprintContextPropsState = {
  currentFingerprint: undefined,
  setCurrentFingerprint: () => {}
};

const FingerprintContext = createContext<FingerprintContextProps>(initFingerprintContextPropsState);

const useFingerprint = () => {
  return useContext(FingerprintContext);
};

const FingerprintProvider: FC<WithChildren> = ({ children }) => {
  const [currentFingerprint, setCurrentFingerprint] = useState<string | undefined>();

  return (
    <FingerprintContext.Provider value={{ currentFingerprint, setCurrentFingerprint }}>
      {children}
    </FingerprintContext.Provider>
  );
};

const FingerprintInit: FC<WithChildren> = ({ children }) => {
  const { currentFingerprint, setCurrentFingerprint } = useFingerprint();

  useEffect(() => {
    const savedFingerprint = localStorage.getItem('lmfp');

    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      localStorage.setItem('lmfp', visitorId);
      setCurrentFingerprint(visitorId);
    };

    if (!savedFingerprint) {
      setFp();
    } else {
      setCurrentFingerprint(savedFingerprint);
    }
  }, []);

  return children;
};

export { FingerprintProvider, useFingerprint, FingerprintInit };
