import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Release } from '../core/_models';
import { DateTime } from 'luxon';

const ReleaseUpcomingBadge = (props: { release: Release }) => {
  return (
    <>
      {props.release.release_date &&
        DateTime.fromISO(props.release.release_date) > DateTime.local() && (
          <span className="d-flex align-items-center text-gray-500 me-5">
            <span className="badge badge-primary">upcoming release</span>
          </span>
        )}
    </>
  );
};

export { ReleaseUpcomingBadge };
