import { useCallback, useEffect, useState } from 'react';
import { UserEditModalHeader } from '../../apps/user-management/users-list/user-edit-modal/UserEditModalHeader';
import { KTIcon } from '../../../../_metronic/helpers';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Track } from './core/_models';
import { DisplayArtistName } from '../artist/DisplayArtistName';
import { removeEmpty } from '../generics/utils';
import { createTrack, getTrack, updateTrack } from './core/_requests';
import { ArtistTypeahead } from '../artist/ArtistTypeahead';
import DragDropArtistList from '../artist/DragDropArtistList';
import { Artist } from '../core/_models';
import { Dropzone } from '../../medias/Dropzone';
import { deleteMedia } from '../../medias/core/_requests';
import { Media } from '../../medias/core/_models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { create } from 'domain';

const UploadCreateTrackModal = (props: {
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShow: any;
  releaseId: string;
  trackNumber: number;
  onExit?: () => void;
}) => {
  function createTrackFromMedia(media: Media) {
    if (props.releaseId) {
      createTrack(props.releaseId, {
        name: media.name,
        audio_master_id: media.id,
        track_number: props.trackNumber
      }).then(() => {
        props.setShow(false);
      });
    }
  }

  return (
    <>
      <Modal show={props.show} onExit={props.onExit} size="lg">
        <Modal.Body>
          <div className="row mb-5">
            <div className="col">
              <p className="fs-4">Create a track directly by dropping an audio file below:</p>
            </div>
          </div>
          <div className="row h-350px">
            <div className="col">
              <Dropzone
                addMedia={createTrackFromMedia}
                restrictions={{
                  maxNumberOfFiles: 1,
                  allowedFileTypes: ['.wav', 'audio/x-wav']
                }}
                public={false}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export { UploadCreateTrackModal };
