import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth';
import { useState } from 'react';
import { patchUser } from '../../../modules/user/core/_requests';
import { toast } from 'react-toastify';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { useQuery } from 'react-query';
import {
  getOrganizationUsers,
  removeOrganizationUser
} from '../../../modules/organization/core/_requests';
import { DateTime } from 'luxon';
import Skeleton from 'react-loading-skeleton';
import { UserInviteModal } from '../../../modules/organization/components/UserInviteModal';

const OrganizationSettingsUsersPage = function () {
  const { currentUser } = useAuth();
  const [showUserInviteModal, setShowUserInviteModal] = useState(false);

  const { currentOrganization } = useOrganization();

  const {
    data: users,
    isLoading,
    refetch
  } = useQuery(
    ['organizationUsers', `${currentOrganization?.id}`],
    async () => {
      const response = await getOrganizationUsers(`${currentOrganization?.id}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!currentOrganization,
      staleTime: 1000 * 60 * 5
    }
  );

  const removeUser = async (userId: string) => {
    if (confirm('Are you sure you want to remove this user?') && currentOrganization) {
      try {
        await removeOrganizationUser(`${currentOrganization.id!}`, userId);
        await refetch();
        toast.success('User removed successfully');
      } catch (error) {
        console.error(error);
        toast.error('An error occured while removing the user. Please try again later.');
      }
    }
  };

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Users</h3>
        </div>
        <div className="card-body border-top p-9">
          <div className="row mb-5">
            <div className="col text-end">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setShowUserInviteModal(true)}>
                Invite user
              </button>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Last login</th>
                  <th>Rôle</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <Skeleton />
                    </td>
                  </tr>
                ) : (
                  <>
                    {' '}
                    {users && users.results.length === 0 && (
                      <tr>
                        <td colSpan={5} className="text-center">
                          No users
                        </td>
                      </tr>
                    )}
                    {users &&
                      users.results.length > 0 &&
                      users.results?.map((user) => (
                        <tr key={user.id}>
                          <td>{user.email}</td>
                          <td>
                            {user.first_name} {user.last_name}
                          </td>
                          <td>
                            {user.last_login != null
                              ? DateTime.fromISO(user.last_login).toLocaleString()
                              : 'N/A'}
                          </td>
                          <td>
                            {
                              {
                                organization_owner: (
                                  <span className="badge badge-light-primary">Owner</span>
                                ),
                                organization_editor: (
                                  <span className="badge badge-light-info">Editor</span>
                                ),
                                organization_viewer: (
                                  <span className="badge badge-light-info">Viewer</span>
                                )
                              }[user.role]
                            }
                          </td>
                          <td>
                            {currentUser && currentUser.email !== user.email ? (
                              <>
                                <button
                                  className="btn btn-danger btn-sm btn-icon me-3"
                                  onClick={() => removeUser(`${user.id}`)}>
                                  <i className="fa fa-trash"></i>
                                </button>
                              </>
                            ) : (
                              <>(it's you)</>
                            )}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <UserInviteModal organization={currentOrganization!} show={showUserInviteModal} setShow={setShowUserInviteModal} />
    </>
  );
};

export default OrganizationSettingsUsersPage;
