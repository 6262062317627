import Skeleton from "react-loading-skeleton";

const FanlinkPageSkeleton = function () {
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-20 landing-artwork">
          <div className="col-md-12">
            <Skeleton width={400} height={400} />
          </div>
        </div>
        <div className="row mb-20">
          <div className="col-md-12">
            <div className="landing-content">
              <div className="row">
                <div className="col-md-12 landing-content--text">
                  <h1 className="title">
                    <Skeleton width={250} />
                  </h1>
                  <h2 className="description">
                    <Skeleton width={300} />
                  </h2>
                </div>
              </div>

              <div className="row mt-3 landing-row-header">
                <div className="col-md-12 text-center">
                  <div className="large-triangle"></div>
                  <div className="small-triangle"></div>
                </div>
              </div>
              <div className="row landing-row provider">
                <div className="col-sm-7 text-center my-auto">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-sm-5 text-center mt-3 mt-sm-0">
                  <Skeleton width={100} />
                </div>
              </div>
              <div className="row landing-row provider">
                <div className="col-sm-7 text-center my-auto">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-sm-5 text-center mt-3 mt-sm-0">
                  <Skeleton width={100} />
                </div>
              </div>
              <div className="row landing-row provider">
                <div className="col-sm-7 text-center my-auto">
                  <Skeleton height={30} width={100} />
                </div>
                <div className="col-sm-5 text-center mt-3 mt-sm-0">
                  <Skeleton width={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FanlinkPageSkeleton;
