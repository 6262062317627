/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { PrivateRoutes } from './PrivateRoutes';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { Logout, AuthPage, useAuth } from '../modules/auth';
import { App } from '../App';
import FanlinkPage from '../pages/fanlink/FanlinkPage';
import EmailOptinPage from '../pages/marketing/EmailOptinPage';
import OAuthCallbackPage from '../pages/oauth/OAuthCallbackPage';
import PromoLinkPage from '../pages/promolink/PromoLinkPage';
import { OrganizationInit } from '../modules/organization/core/Organization';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { BASE_URL } = import.meta.env;

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  return (
    <BrowserRouter basename={BASE_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route path="dashboard" element={<Navigate to="/manager/dashboard" />} />
          <Route path="fanlink/*" element={<FanlinkPage />} />
          <Route path="promo/*" element={<PromoLinkPage />} />
          <Route path="marketing/optin/:optinId" element={<EmailOptinPage />} />
          <Route path="oauth/:platform/callback" element={<OAuthCallbackPage />} />

          {currentUser ? (
            <>
              <Route path="auth/*" element={<Navigate to="/manager/choose-organization" />} />

              <Route path="/manager/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/manager/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
