import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../../modules/auth';
import { useState } from 'react';
import { patchUser } from '../../../modules/user/core/_requests';
import { toast } from 'react-toastify';

const UserUpdateSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required')
});

const AccountSettingsInformationsPage = function () {
  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const formik = useFormik<{ email: string; first_name: string; last_name: string }>({
    initialValues: {
      email: currentUser?.email || '',
      first_name: currentUser?.first_name || '',
      last_name: currentUser?.last_name || ''
    },
    validationSchema: UserUpdateSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data: result } = await patchUser(currentUser!.id.toString(), values);
        setCurrentUser(result);
        setLoading(false);
        toast.success('Account informations updated!');
      } catch (error) {
        setLoading(false);
        console.error(error);
        formik.setStatus(
          'An error occured while updating your informations. Please try again later.'
        );
      }
    }
  });

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Account Informations</h3>
        </div>
        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Email :</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="john.doe@labelmanager.io"
                        {...formik.getFieldProps('email')}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    First name :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="John"
                        {...formik.getFieldProps('first_name')}
                      />
                    </div>
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Last name :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Doe"
                        {...formik.getFieldProps('last_name')}
                      />
                    </div>
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting || !formik.isValid || loading}>
                  {!loading && <span className="indicator-label">Update account informations</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountSettingsInformationsPage;
