import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getFanlinkStats } from '../core/_requests';
import { useQuery } from 'react-query';
import Chart from 'react-apexcharts';
import { useState } from 'react';
import { getPlatfomData } from '../generics/utils';

const ReleaseFanlinkStatsCard = (props: { fanlinkId: string }) => {
  const [chartState, setChartState] = useState<{
    options: { labels: string[]; colors: string[] };
    series: number[];
  }>({
    options: {
      labels: [],
      colors: []
    },
    series: []
  });

  const { data: fanlinkStats, isFetching } = useQuery(
    ['fanlinkStats', `${props.fanlinkId}`],
    async () => {
      const { data: result } = await getFanlinkStats(props.fanlinkId);

      const labels = Object.entries(result?.platforms_clicks).map(([key, value]) => {
        return getPlatfomData(key) !== null ? getPlatfomData(key)!.name : key;
      });
      const colors = Object.entries(result?.platforms_clicks).map(([key, value]) => {
        return getPlatfomData(key) !== null ? getPlatfomData(key)!.color : key;
      });
      const series = Object.entries(result?.platforms_clicks).map(([key, value]) => {
        return value;
      });

      setChartState({
        options: {
          labels: labels,
          colors: colors
        },
        series: series
      });

      return result;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Fanlink overview</h3>
        </div>
        <div className="card-body border-top p-9">
          <div className="row">
            <div className="col">
              <h3>Audience</h3>
              <div className="row mt-5">
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder counted">
                        {isFetching ? <Skeleton width={60} /> : fanlinkStats?.unique_page_views}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">unique views</div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder counted">
                        {isFetching ? <Skeleton width={60} /> : fanlinkStats?.unique_clicks}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">unique clicks</div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {isFetching ? <Skeleton width={60} /> : fanlinkStats?.page_views}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">total page views</div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {' '}
                        {isFetching ? <Skeleton width={60} /> : fanlinkStats?.clicks}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">total clicks</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {isFetching ? (
                          <Skeleton width={60} />
                        ) : (
                          <>
                            {fanlinkStats?.unique_page_views && fanlinkStats?.unique_page_views > 0
                              ? Math.round(
                                  (fanlinkStats?.unique_clicks / fanlinkStats.unique_page_views) *
                                    100
                                ) + '%'
                              : 0}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">conversion rate</div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-4 col-xs-6">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {isFetching ? <Skeleton width={60} /> : fanlinkStats?.presave_count}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">pre-saves</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-10">
            <div className="col">
              <h3>Streaming platforms</h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              {chartState.series.length === 0 ? (
                <>
                  <div className="row mt-5">
                    <div className="col text-center text-muted fst-italic">
                      <p>There is no data at this moment ... Please come back later!</p>
                    </div>
                  </div>
                </>
              ) : (
                <Chart
                  options={chartState.options as unknown as ApexCharts.ApexOptions}
                  series={chartState.series}
                  type="pie"
                  width="100%"
                  height={350}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ReleaseFanlinkStatsCard };
