import axios from 'axios';
import { AuthModel, UserModel } from './_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const VERIFY_TOKEN_URL = `${API_URL}/api/auth/token/verify/`;
export const LOGIN_URL = `${API_URL}/api/auth/token/`;
export const REFRESH_TOKEN_URL = `${API_URL}/api/auth/token/refresh/`;

export const GET_CURRENT_USER_URL = `${API_URL}/api/users/me/`;
export const REGISTER_URL = `${API_URL}/register/`;

export const USERS_URL = `${API_URL}/api/users/`;

export const POST_REQUEST_PASSWORD_URL = `${API_URL}/api/users/forgotten_password/`;

// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  });
}

export function requestPassword(email: string) {
  return axios.post(POST_REQUEST_PASSWORD_URL, {
    email
  });
}

export function resetPassword(userId: string, token: string, password: string) {
  return axios.post(USERS_URL + userId + '/reset_password/', {
    token,
    password
  });
}

export function verifyToken(token: string) {
  return axios.post<UserModel>(VERIFY_TOKEN_URL, {
    token
  });
}

export function getUserByToken(access_token: string) {
  return axios.get<UserModel>(GET_CURRENT_USER_URL, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function refreshAccessToken(refreshToken: string) {
  return axios.post<AuthModel>(REFRESH_TOKEN_URL, {
    refresh: refreshToken
  });
}
