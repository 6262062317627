import React from 'react';
import { Artist } from '../core/_models';

export function DisplayArtistName(props: { artists?: Artist[]; default?: string }) {
  if (props.artists === undefined) {
    return <></>;
  }

  if (props.artists.length === 1) {
    return <>{props.artists[0].name}</>;
  }

  return (
    <>
      {props.artists.length ? (
        props.artists.map((artist, index) => {
          return (
            <React.Fragment key={artist.id}>
              {artist.name}
              {index === props.artists!.length - 1 ? '' : ', '}{' '}
            </React.Fragment>
          );
        })
      ) : (
        <>{props.default ? props.default : '(no artists)'}</>
      )}
    </>
  );
}
