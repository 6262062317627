import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SearchComponent } from '../../../assets/ts/components';
import { KTIcon, toAbsoluteUrl, useDebounce } from '../../../helpers';
import { getSearch } from '../../../../app/modules/search/core/_requests';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { DisplayArtistName } from '../../../../app/modules/catalog/artist/DisplayArtistName';
import { useFormik } from 'formik';

const SearchSchema = Yup.object().shape({
  search: Yup.string()
});

interface SearchFormValue {
  search: string;
}

const Search: FC = () => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearch = useDebounce(searchTerm, 500);

  const element = useRef<HTMLDivElement | null>(null);
  const wrapperElement = useRef<HTMLDivElement | null>(null);
  const resultsElement = useRef<HTMLDivElement | null>(null);
  // const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null);

  const formik = useFormik<SearchFormValue>({
    initialValues: {
      search: ''
    },
    validationSchema: SearchSchema,
    onSubmit: async (values) => {
      console.log('values', values);
      setSearchTerm(values.search);
    }
  });

  const {
    data: searchResult,
    isFetching,
    refetch
  } = useQuery(
    ['search', debouncedSearch],
    async () => {
      if (!debouncedSearch || debouncedSearch.length === 0) {
        return;
      }
      const { data: result } = await getSearch(debouncedSearch!);
      return result;
    },
    {
      refetchOnMount: false,
      enabled: debouncedSearch != undefined && debouncedSearch.length > 0
    }
  );

  function clear() {
    setSearchTerm('');
    formik.resetForm();
    refetch();
    console.log('CLEAR');
  }

  function onResultClick() {
    clear();
    SearchComponent.createInsance('#kt_header_search')?.hide();
  }

  useEffect(() => {
    // Initialize search handler
    const searchObject = SearchComponent.createInsance('#kt_header_search');
  }, []);

  return (
    <>
      <div
        id="kt_header_search"
        className="d-flex align-items-stretch"
        data-kt-search-keypress="true"
        data-kt-search-min-length="2"
        data-kt-search-enter="enter"
        data-kt-search-layout="menu"
        data-kt-menu-trigger="auto"
        data-kt-menu-overflow="false"
        data-kt-menu-permanent="true"
        data-kt-menu-placement="bottom-end"
        ref={element}>
        <div
          className="d-flex align-items-center"
          data-kt-search-element="toggle"
          id="kt_header_search_toggle">
          <div className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
            <KTIcon iconName="magnifier" className="fs-1" />
          </div>
        </div>

        <div
          data-kt-search-element="content"
          className="menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px">
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element="wrapper">
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              className="w-100 position-relative mb-3"
              autoComplete="off">
              <KTIcon
                iconName="magnifier"
                className="fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0"
              />

              <input
                type="text"
                className="form-control form-control-flush ps-10"
                placeholder="Search..."
                {...formik.getFieldProps('search')}
                data-kt-search-element="input"
                onChange={(e) => {
                  formik.setFieldValue('search', e.target.value);
                  setSearchTerm(e.target.value);
                }}
              />

              {isFetching && (
                <span
                  className="position-absolute top-50 end-0 translate-middle-y lh-0 me-1"
                  data-kt-search-element="spinner">
                  <span className="spinner-border h-15px w-15px align-middle text-gray-500" />
                </span>
              )}

              {!isFetching && searchTerm.length > 0 && (
                <span
                  className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0"
                  onClick={() => clear()}>
                  <KTIcon iconName="cross" className="fs-2 text-lg-1 me-0" />
                </span>
              )}

              {/* <div
                className='position-absolute top-50 end-0 translate-middle-y'
                data-kt-search-element='toolbar'
              >
                <div
                  data-kt-search-element='preferences-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary me-1'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('preferences')
                  }}
                  title='Show search preferences'
                >
                  <KTIcon iconName='setting-2' className='fs-1' />
                </div>

                <div
                  data-kt-search-element='advanced-options-form-show'
                  className='btn btn-icon w-20px btn-sm btn-active-color-primary'
                  data-bs-toggle='tooltip'
                  onClick={() => {
                    setMenuState('advanced')
                  }}
                  title='Show more search options'
                >
                  <KTIcon iconName='down' className='fs-2' />
                </div>
              </div> */}
            </form>

            {searchResult && (searchResult.tracks.length > 0 || searchResult.albums.length > 0) && (
              <div ref={resultsElement} data-kt-search-element="results">
                <div className="scroll-y mh-200px mh-lg-350px">
                  {searchResult.albums.length > 0 && (
                    <>
                      <h3
                        className="fs-5 text-muted m-0 pb-5"
                        data-kt-search-element="category-title">
                        Releases
                      </h3>

                      {searchResult.albums.map((album, index) => (
                        <Link
                          key={album.id + '-' + index}
                          to={`/manager/catalog/releases/${album.id}/`}
                          onClick={() => {
                            onResultClick();
                          }}
                          className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">
                          <div className="symbol symbol-40px me-4">
                            <img src={album.artwork?.public_url} alt="" />
                          </div>

                          <div className="d-flex flex-column justify-content-start fw-semibold">
                            <span className="fs-6 fw-semibold">{album.name}</span>
                            <span className="fs-7 fw-semibold text-muted">
                              <DisplayArtistName artists={album.artists} />
                            </span>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}

                  {searchResult.tracks.length > 0 && (
                    <>
                      <h3
                        className="fs-5 text-muted m-0 pb-5"
                        data-kt-search-element="category-title">
                        Tracks
                      </h3>

                      {searchResult.tracks.map((track, index) => (
                        <Link
                          key={track.id + '-' + index}
                          to={`/manager/catalog/tracks/${track.id}/`}
                          onClick={() => {
                            onResultClick();
                          }}
                          className="d-flex text-gray-900 text-hover-primary align-items-center mb-5">
                          <div className="symbol symbol-40px me-4">
                            <div className="symbol-label bg-light">
                              <i className="bi bi-music-note-beamed"></i>
                            </div>
                          </div>

                          <div className="d-flex flex-column justify-content-start fw-semibold">
                            <span className="fs-6 fw-semibold">{track.name}</span>
                            <span className="fs-7 fw-semibold text-muted">
                              <DisplayArtistName artists={track.artists} />
                            </span>
                          </div>
                        </Link>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}

            {/* <div ref={suggestionsElement} className='mb-4' data-kt-search-element='main'>
              <div className='d-flex flex-stack fw-semibold mb-4'>
                <span className='text-muted fs-6 me-2'>Recently Searched:</span>
              </div>

              <div className='scroll-y mh-200px mh-lg-325px'>
                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='phone' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      BoomApp by Keenthemes
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#45789</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='chart-simple' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      "Kept API Project Meeting
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84050</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='chart' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      "KPI Monitoring App Launch
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84250</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='chart-simple-3' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      Project Reference FAQ
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#67945</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='sms' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      "FitPro App Development
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#84250</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='bank' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      Shopix Mobile App
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#45690</span>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-5'>
                  <div className='symbol symbol-40px me-4'>
                    <span className='symbol-label bg-light'>
                      <KTIcon iconName='chart-simple-3' className='fs-2 text-primary' />
                    </span>
                  </div>

                  <div className='d-flex flex-column'>
                    <a href='/#' className='fs-6 text-gray-800 text-hover-primary fw-semibold'>
                      "Landing UI Design" Launch
                    </a>
                    <span className='fs-7 text-muted fw-semibold'>#24005</span>
                  </div>
                </div>
              </div>
            </div> */}

            {searchResult && searchResult.tracks.length == 0 && searchResult.albums.length == 0 && (
              <div data-kt-search-element="empty" className="text-center">
                <div className="pt-10 pb-10">
                  <KTIcon iconName="search-list" className="fs-4x opacity-50" />
                </div>

                <div className="pb-15 fw-semibold">
                  <h3 className="text-gray-600 fs-5 mb-2">No result found</h3>
                  <div className="text-muted fs-7">Please try again with a different query</div>
                </div>
              </div>
            )}
          </div>

          {/* <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
            <h3 className='fw-semibold text-gray-900 mb-7'>Advanced Search</h3>

            <div className='mb-5'>
              <input
                type='text'
                className='form-control form-control-sm form-control-solid'
                placeholder='Contains the word'
                name='query'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    All
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='users' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Users
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='orders' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Orders
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='projects' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Projects
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='assignedto'
                className='form-control form-control-sm form-control-solid'
                placeholder='Assigned to'
              />
            </div>

            <div className='mb-5'>
              <input
                type='text'
                name='collaborators'
                className='form-control form-control-sm form-control-solid'
                placeholder='Collaborators'
              />
            </div>

            <div className='mb-5'>
              <div className='nav-group nav-group-fluid'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='attachment'
                    value='has'
                    defaultChecked
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    Has attachment
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='attachment' value='any' />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    Any
                  </span>
                </label>
              </div>
            </div>

            <div className='mb-5'>
              <select
                name='timezone'
                aria-label='Select a Timezone'
                data-control='select2'
                data-placeholder='date_period'
                className='form-select form-select-sm form-select-solid'
              >
                <option value='next'>Within the next</option>
                <option value='last'>Within the last</option>
                <option value='between'>Between</option>
                <option value='on'>On</option>
              </select>
            </div>

            <div className='row mb-8'>
              <div className='col-6'>
                <input
                  type='number'
                  name='date_number'
                  className='form-control form-control-sm form-control-solid'
                  placeholder='Lenght'
                />
              </div>

              <div className='col-6'>
                <select
                  name='date_typer'
                  aria-label='Select a Timezone'
                  data-control='select2'
                  data-placeholder='Period'
                  className='form-select form-select-sm form-select-solid'
                >
                  <option value='days'>Days</option>
                  <option value='weeks'>Weeks</option>
                  <option value='months'>Months</option>
                  <option value='years'>Years</option>
                </select>
              </div>
            </div>

            <div className='d-flex justify-content-end'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bold btn-active-light-primary me-2'
              >
                Cancel
              </button>

              <a
                href='/#'
                className='btn btn-sm fw-bold btn-primary'
                data-kt-search-element='advanced-options-form-search'
              >
                Search
              </a>
            </div>
          </form> */}

          {/* <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
            <h3 className='fw-semibold text-gray-900 mb-7'>Search Preferences</h3>

            <div className='pb-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Projects
                </span>

                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Targets
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Affiliate Programs
                </span>
                <input className='form-check-input' type='checkbox' value='1' />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Referrals
                </span>
                <input className='form-check-input' type='checkbox' value='1' defaultChecked />
              </label>
            </div>

            <div className='py-4 border-bottom'>
              <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                <span className='form-check-label text-gray-700 fs-6 fw-semibold ms-0 me-2'>
                  Users
                </span>
                <input className='form-check-input' type='checkbox' />
              </label>
            </div>

            <div className='d-flex justify-content-end pt-7'>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setMenuState('main')
                }}
                className='btn btn-sm btn-light fw-bold btn-active-light-primary me-2'
              >
                Cancel
              </button>
              <button className='btn btn-sm fw-bold btn-primary'>Save Changes</button>
            </div>
          </form> */}
        </div>
      </div>
    </>
  );
};

export { Search };
