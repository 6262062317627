import { useCallback, useEffect, useState } from 'react';
import { UserEditModalHeader } from '../../apps/user-management/users-list/user-edit-modal/UserEditModalHeader';
import { KTIcon } from '../../../../_metronic/helpers';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import Select from 'react-select';
import { Organization, UserInvitation } from '../core/_models';
import { inviteUser } from '../core/_requests';
import { toast } from 'react-toastify';

const UserInviteSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  role: Yup.string()
});

const UserInviteModal = (props: {
  organization: Organization;
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShow: any;
}) => {
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const formik = useFormik<UserInvitation>({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      role: 'organization_viewer'
    },
    validationSchema: UserInviteSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        await inviteUser(values);
        props.setShow(false);
        queryClient.invalidateQueries(['organizationUsers', `${props.organization.id}`]);
        setLoading(false);
        toast.success('Invitation sent');
      } catch (error) {
        console.error(error);
        formik.setStatus(
          'An error occured while inviting this user. Please try again. Our team has been notified.'
        );
        setLoading(false);
      }
    }
  });

  return (
    <>
      <Modal show={props.show} className="modal" onHide={() => props.setShow(false)}>
        <Modal.Header>
          <Modal.Title>Invite a new user to organization</Modal.Title>
          <div
            className="btn btn-icon btn-sm btn-active-icon-primary"
            data-kt-users-modal-action="close"
            onClick={() => props.setShow(false)}
            style={{ cursor: 'pointer' }}>
            <KTIcon iconName="cross" className="fs-1" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">
              <p>
                To invite a user to join your account, please fill-in the requested informations
                below:{' '}
              </p>
            </div>
          </div>

          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Email:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="email@labelmanager.io"
                        {...formik.getFieldProps('email')}
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Firstname:
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="John"
                        {...formik.getFieldProps('first_name')}
                      />
                    </div>
                    {formik.touched.first_name && formik.errors.first_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.first_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Lastname:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Doe"
                        {...formik.getFieldProps('last_name')}
                      />
                    </div>
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Role:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <Select
                        className="react-select-solid react-select-styled react-select-lg w-100"
                        classNamePrefix="react-select"
                        defaultValue={{
                          value: 'organization_viewer',
                          label: 'Organization viewer'
                        }}
                        isSearchable={false}
                        options={[
                          { value: 'organization_viewer', label: 'Organization viewer' },
                          { value: 'organization_editor', label: 'Organization editor' }
                        ]}
                        placeholder="Select an option"
                        onChange={(option) => formik.setFieldValue('role', option?.value)}
                      />
                    </div>
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => formik.handleSubmit()}
            disabled={formik.isSubmitting}>
            {loading && <span className="spinner spinner-white spinner-sm spinner-left"></span>}
            Add user
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { UserInviteModal };
