import { useInfiniteQuery } from 'react-query';
import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { getReleases } from '../../../modules/catalog/core/_requests';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { LoadingReleaseCard } from '../../../modules/catalog/release/LoadingReleaseCard';
import { DisplayArtistName } from '../../../modules/catalog/artist/DisplayArtistName';

const ListCatalogPage = function () {
  const pageSize = 24;
  // const [releases, setReleases] = React.useState<Release[]>([]);

  const fetchReleases = async ({ pageParam = 0 }) => {
    const res = getReleases(pageParam * pageSize, pageSize);
    return res;
  };

  const {
    data: releases,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status
  } = useInfiniteQuery({
    queryKey: ['releases'],
    queryFn: fetchReleases,
    getNextPageParam: (lastPage, pages) => {
      if (pages.length === 0) return 0;
      if (lastPage.data.next) return pages.length;
      return null;
    },
    refetchOnWindowFocus: false
  });

  return (
    <>
      <PageTitle breadcrumbs={[]}>Release catalog</PageTitle>
      <Toolbar />
      <Content>
        <InfiniteScroll
          style={{ overflow: 'hidden' }}
          dataLength={
            // We need to calculate length of all pages
            releases?.pages.reduce((sum, page) => sum + page.data.results.length, 0) || 0
          } //This is important field to render the next data
          next={fetchNextPage}
          hasMore={isFetching || hasNextPage!}
          loader={
            <>
              <div className="row">
                <LoadingReleaseCard />
                <LoadingReleaseCard />
                <LoadingReleaseCard />
                <LoadingReleaseCard />
              </div>
            </>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You've reached the end of your releases!</b>
            </p>
          }>
          <div className="row">
            {releases &&
              releases!.pages.map((group, i) => (
                <React.Fragment key={i}>
                  {group.data.results.map((release) => (
                    <div className="col-md-6 col-sm-12 col-xl-3" key={release.id}>
                      <div className="card mb-5">
                        <div className="card-body">
                          <div className="row d-flex mb-5">
                            <div className="col">
                              <img
                                src={release.artwork?.public_url}
                                alt={release.name}
                                className="w-90px"
                              />
                            </div>
                            <div className="col">
                              <span className="fs-3">
                                <DisplayArtistName artists={release.artists} /> - {release.name}
                              </span>
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div className="col">
                              <span className="fs-6 fw-bolder">Release date:</span>
                            </div>
                            <div className="col text-end">
                              {DateTime.fromISO(release.release_date!).toLocaleString()}
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col">
                              <span className="fs-6 fw-bolder">Catalog :</span>
                            </div>
                            <div className="col text-end">
                              <span className="badge badge-dark">
                                {release.catalog_number || 'N/A'}
                              </span>
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col">
                              <span className="fs-6 fw-bolder">UPC:</span>
                            </div>
                            <div className="col text-end">{release.upc}</div>
                          </div>
                          <div className="row mt-5">
                            <div className="col text-center">
                              <Link
                                to={'/manager/catalog/releases/' + release.id}
                                className="btn btn-light-primary">
                                View release
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
          </div>
        </InfiniteScroll>
      </Content>
    </>
  );
};

export default ListCatalogPage;
