import { useCallback } from 'react';
import { Artist, ReleaseFanlinkPlatformLink, ReleaseFanlinkStoreLink } from '../core/_models';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getPlatfomData } from './utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './star_rating.scss';

const starTooltips = ['Very bad', 'Poor', 'Okay', 'Good', 'Excellent'];

function StarRating(props: { onRating: (rating: number) => void }) {
  return (
    <>
      <div className="star-rating d-flex justify-content-center align-items-center">
        <div className="rating">
          {/* Iterate 5 times for each item */}
          {Array.from({ length: 5 }, (_, i) => i).map((index) => (
            <div
              key={index}
              className="star ms-1 me-1"
              onClick={() => {
                if (props.onRating) {
                  props.onRating(index + 1);
                }
              }}>
              <OverlayTrigger
                overlay={<Tooltip className="mb-3">{starTooltips[index]}</Tooltip>}
                placement="top"
                trigger={['focus', 'hover']}>
                <FontAwesomeIcon icon={faStar} fontSize={'2rem'} />
              </OverlayTrigger>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default StarRating;
