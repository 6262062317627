import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flatpickr from 'react-flatpickr';
// @ts-expect-error no types available
import { codes } from 'iso-country-codes';
import Select from 'react-select';
import { useAuth } from '../../../modules/auth';
import { useEffect, useState } from 'react';
import { patchUser } from '../../../modules/user/core/_requests';
import { toast } from 'react-toastify';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { Organization, OrganizationForm } from '../../../modules/organization/core/_models';
import { DateTime } from 'luxon';
import AutoComplete from 'react-google-autocomplete';
import { LMAddressToString, googleToLMAddress } from '../../../generics/utils';
import { Address, CountryCode } from '../../../generics/_models';
import { patchOrganization } from '../../../modules/organization/core/_requests';

const OrganizationUpdateSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  stakeholder_first_name: Yup.string().required('First name is required'),
  stakeholder_last_name: Yup.string().required('Last name is required'),
  stakeholder_date_of_birth: Yup.string().required('Date of birth is required'),
  stakeholder_address_formatted: Yup.string().required('Address is required'),
  stakeholder_email: Yup.string().email('Invalid email').required('Email is required'),
  stakeholder_nationality: Yup.string().required('Nationality is required')
});

const OrganizationSettingsInformationPage = function () {
  // const { currentUser, setCurrentUser } = useAuth();
  const { currentOrganization, setCurrentOrganization } = useOrganization();
  const [addressObject, setAddressObject] = useState<Address>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddressObject(currentOrganization?.stakeholder.address);
  }, []);

  const formik = useFormik<OrganizationForm>({
    initialValues: {
      name: currentOrganization?.name || '',
      stakeholder_first_name: currentOrganization?.stakeholder.firstname || '',
      stakeholder_last_name: currentOrganization?.stakeholder.lastname || '',
      stakeholder_date_of_birth: currentOrganization?.stakeholder.date_of_birth || '',
      stakeholder_address_formatted: currentOrganization?.stakeholder.address
        ? LMAddressToString(currentOrganization?.stakeholder.address)
        : '',
      stakeholder_nationality: currentOrganization?.stakeholder.nationality || '',
      stakeholder_email: currentOrganization?.stakeholder.email || ''
    },
    validationSchema: OrganizationUpdateSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const { data: organization } = await patchOrganization(`${currentOrganization?.id}`, {
          name: values.name,
          stakeholder: {
            legal_name: values.name!,
            nationality: values.stakeholder_nationality,
            firstname: values.stakeholder_first_name!,
            lastname: values.stakeholder_last_name!,
            date_of_birth: values.stakeholder_date_of_birth!,
            address: addressObject!,
            email: values.stakeholder_email!
          }
        });
        setLoading(false);
        toast.success('Organization informations updated!');
      } catch (error) {
        setLoading(false);
        console.error(error);
        formik.setStatus(
          'An error occured while updating your organization informations. Please try again later.'
        );
      }
    }
  });

  return (
    <>
      <div className="card mb-5">
        <div className="card-header">
          <h3 className="card-title">Organization Informations</h3>
        </div>
        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Name :</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Label Manager Inc."
                        {...formik.getFieldProps('name')}
                      />
                    </div>
                    {formik.touched.name && formik.errors.name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    First name :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="John"
                        {...formik.getFieldProps('stakeholder_first_name')}
                      />
                    </div>
                    {formik.touched.stakeholder_first_name && formik.errors.stakeholder_first_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.stakeholder_first_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Last name :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Doe"
                        {...formik.getFieldProps('stakeholder_last_name')}
                      />
                    </div>
                    {formik.touched.stakeholder_last_name && formik.errors.stakeholder_last_name && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.stakeholder_last_name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Contact email :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="contact@labelmanager.io"
                        {...formik.getFieldProps('stakeholder_email')}
                      />
                    </div>
                    {formik.touched.stakeholder_email && formik.errors.stakeholder_email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.stakeholder_email}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Date of birth :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <Flatpickr
                        value={
                          formik.values.stakeholder_date_of_birth
                            ? DateTime.fromISO(formik.values.stakeholder_date_of_birth).toJSDate()
                            : undefined
                        }
                        onChange={([date1]) => {
                          formik.setFieldValue(
                            'stakeholder_date_of_birth',
                            DateTime.fromJSDate(date1).toISODate()
                          );
                        }}
                        className="form-control"
                        placeholder="Pick date"
                      />
                    </div>
                    {formik.touched.stakeholder_date_of_birth &&
                      formik.errors.stakeholder_date_of_birth && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.stakeholder_date_of_birth}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Country :</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <Select
                        className="react-select-solid react-select-styled react-select-lg w-100"
                        classNamePrefix="react-select"
                        defaultValue={{
                          value: formik.values.stakeholder_nationality,
                          label: codes.find(
                            (code: CountryCode) =>
                              code.alpha3 === formik.values.stakeholder_nationality
                          )?.name
                        }}
                        onChange={(value) => {
                          formik.setFieldValue('stakeholder_nationality', value?.value);
                        }}
                        options={codes.map((code: CountryCode) => ({
                          value: code.alpha3,
                          label: code.name
                        }))}
                      />
                    </div>
                    {formik.touched.stakeholder_nationality &&
                      formik.errors.stakeholder_nationality && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.stakeholder_nationality}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Address :</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <AutoComplete
                        className="form-control form-control-lg form-control-solid"
                        options={{ types: ['geocode'] }}
                        apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY || ''}
                        defaultValue={formik.values.stakeholder_address_formatted}
                        onPlaceSelected={(place) => {
                          formik.setFieldValue(
                            'stakeholder_address_formatted',
                            place.formatted_address
                          );
                          const processedAddress = googleToLMAddress(place);
                          setAddressObject(processedAddress);
                        }}
                      />
                    </div>
                    {formik.touched.stakeholder_address_formatted &&
                      formik.errors.stakeholder_address_formatted && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.stakeholder_address_formatted}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting || !formik.isValid || loading}>
                  {!loading && (
                    <span className="indicator-label">Update organization informations</span>
                  )}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OrganizationSettingsInformationPage;
