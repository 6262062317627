import axios from 'axios';
import { Paginated } from '../../../generics/_models';
import { ReleaseFanlink } from '../../catalog/core/_models';
import { v4 as uuidv4 } from 'uuid';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const FANLINK_URL = `${API_URL}/api/fanlinks/`;

export function getPublicFanlink(slug: string) {
  // Append a trailing slash to the URL to avoid a 301 redirect
  const finalSlug = slug.endsWith('/') ? slug : slug + '/';
  return axios.get<ReleaseFanlink>(FANLINK_URL + finalSlug);
}

export function fanlinkEmailRegistration(fanlinkId: string, email: string) {
  return axios.post(FANLINK_URL + fanlinkId + '/subscribe_email/', {
    email
  });
}

export function postFanlinkEvent(fanlinkId: string, eventType: string, params: any) {
  let flid = localStorage.getItem('flid');

  if (flid == null) {
    flid = uuidv4();
    localStorage.setItem('flid', flid!);
  }

  return axios.post(FANLINK_URL + fanlinkId + '/events/', {
    type: eventType,
    flid: flid,
    params: {
      ...params,
      referrer: document.referrer
    }
  });
}

export function postFanlinkVisit(fanlinkId: string) {
  return postFanlinkEvent(fanlinkId, 'page_view', {});
}

export function postFanlinkPlatformClick(fanlinkId: string, streamingPlatform: string) {
  return postFanlinkEvent(fanlinkId, 'streaming_platform_click', {
    streaming_platform_slug: streamingPlatform
  });
}

export function postFanlinkPlatformPresave(fanlinkId: string, streamingPlatform: string) {
  return postFanlinkEvent(fanlinkId, 'presave_click', {
    streaming_platform_slug: streamingPlatform
  });
}

export function postFanlinkSpotifyPresave(fanlinkId: string, code: string) {
  return axios.post(FANLINK_URL + fanlinkId + '/spotify_presave/', {
    code
  });
}

export function getLoginRedirectURL(state: string) {
  const params = [
    'response_type=code',
    'state=' + state,
    'client_id=' + import.meta.env.VITE_APP_SPOTIFY_CLIENT_ID,
    'scope=user-read-email,user-follow-modify,user-library-modify',
    'redirect_uri=' +
      encodeURIComponent(import.meta.env.VITE_APP_PUBLIC_URL + '/oauth/spotify/callback')
  ];

  return 'https://accounts.spotify.com/authorize?' + params.join('&');
}
