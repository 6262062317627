import { useQuery, useQueryClient } from 'react-query';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Content } from '../../../../_metronic/layout/components/Content';
import {
  getOrganizationSubscriptionPortalLink,
  getOrganizations
} from '../../../modules/organization/core/_requests';
import { Organization } from '../../../modules/organization/core/_models';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat'];

const RestrictedOrganizationPage = function () {
  const navigate = useNavigate();
  const { mode } = useThemeMode();
  useEffect(() => {
    BODY_CLASSES.forEach((c) => document.body.classList.add(c));
    document.body.style.backgroundImage =
      mode === 'dark'
        ? `url(${toAbsoluteUrl('media/auth/bg7-dark.jpg')})`
        : `url(${toAbsoluteUrl('media/auth/bg7.jpg')})`;

    return () => {
      BODY_CLASSES.forEach((c) => document.body.classList.remove(c));
      document.body.style.backgroundImage = 'none';
    };
  }, [mode]);

  const { currentOrganization } = useOrganization();

  const { data: link, isFetching } = useQuery(
    ['subscriptionPortal', `${currentOrganization?.id}`],
    async () => {
      const response = await getOrganizationSubscriptionPortalLink(`${currentOrganization?.id}`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!currentOrganization,
      staleTime: 1000 * 60 * 30
    }
  );

  useEffect(() => {
    if (currentOrganization && !currentOrganization.restricted) {
      navigate('/manager/dashboard');
    }
  }, [currentOrganization]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-column flex-center flex-column-fluid">
          <div className="d-flex flex-column flex-center text-center p-10">
            <div className="card card-flush  w-lg-650px py-5">
              <div className="card-body py-15 py-lg-20">
                <div className="row">
                  <div className="col text-center">
                    <img src="/media/logos/lm/logo-text-black.png" alt="" width={250} />
                    <div className="fs-2 mt-5">Your subscription has expired.</div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col text-center">
                    <p className="mt-3 mb-10 fs-5">
                      To reactivate your account, please go to the subscription portal, pay your
                      unpaid invoices and renew your subscription.
                      <br />
                      <br />
                      Your account will be enabled again automatically a few minutes after your
                      payment.
                    </p>
                    {isFetching || !link ? (
                      <>
                        <div className="text-muted">Loading...</div>
                      </>
                    ) : (
                      <a href={link.url} target="_blank" className="btn btn-primary">
                        Go to your subscription portal
                      </a>
                    )}

                    <p className="mt-10">
                      <small className="text-muted">
                        If you have any questions or issue renewing your subscription, please
                        contact us on the chat on the bottom right corner of your screen.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { RestrictedOrganizationPage };
