import { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAuth } from '../core/Auth';
import { Link, useParams } from 'react-router-dom';
import { resetPassword } from '../core/_requests';

const passwordResetSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
});

const initialValues = {
  password: '',
  password_confirmation: ''
};

export function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const { userId, token } = useParams();

  const formik = useFormik({
    initialValues,
    validationSchema: passwordResetSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await resetPassword(userId!, token!, values.password);
        setSuccess(true);
      } catch (error) {
        console.error(error);
        setStatus(
          'An error occured while trying to reset your password. Please try again. You may have an expired or invalid link.'
        );
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  return (
    <>
      {success === true ? (
        <>
          <div className="row">
            <div className="col text-center">
              <div className="alert alert-info">Your password has been reset.</div>
              <Link to="/auth/login" className="btn btn-primary mt-3">
                Back to login
              </Link>
            </div>
          </div>
        </>
      ) : (
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_login_signin_form">
          <div className="text-center mb-11">
            <h1 className="text-gray-900 fw-bolder mb-3">Reset your password</h1>
            {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
          </div>

          {formik.status && (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          <div className="fv-row mb-3">
            <label className="form-label fw-bolder text-gray-900 fs-6 mb-0">Password</label>
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className="fv-row mb-3">
            <label className="form-label fw-bolder text-gray-900 fs-6 mb-0">
              Password confirmation
            </label>
            <input
              type="password"
              autoComplete="off"
              {...formik.getFieldProps('password_confirmation')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid':
                    formik.touched.password_confirmation && formik.errors.password_confirmation
                },
                {
                  'is-valid':
                    formik.touched.password_confirmation && !formik.errors.password_confirmation
                }
              )}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password_confirmation}</span>
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col text-center mt-5">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => formik.handleSubmit()}
                disabled={formik.isSubmitting || !formik.isValid || loading}>
                {!loading && <span className="indicator-label">Reset password</span>}
                {loading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
