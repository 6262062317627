import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { Artist, Release } from '../../../../modules/catalog/core/_models';
import { ArtistTypeahead } from '../../../../modules/catalog/artist/ArtistTypeahead';
import DragDropArtistList from '../../../../modules/catalog/artist/DragDropArtistList';
import Flatpickr from 'react-flatpickr';
import { DateTime } from 'luxon';
import { Dropzone } from '../../../../modules/medias/Dropzone';
import { Media } from '../../../../modules/medias/core/_models';
import {
  createRelease,
  getRelease,
  getReleaseTracks
} from '../../../../modules/catalog/core/_requests';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createTrack,
  deleteTrack,
  updateTrack
} from '../../../../modules/catalog/track/core/_requests';
import { DisplayArtistName } from '../../../../modules/catalog/artist/DisplayArtistName';
import { Track } from '../../../../modules/catalog/track/core/_models';
import { TrackUpdateModal } from '../../../../modules/catalog/track/TrackUpdateModal';
import { UploadCreateTrackModal } from '../../../../modules/catalog/track/UploadCreateTrackModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const ReleaseCreateSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  upc: Yup.string().matches(/^[0-9]{12}$/, 'UPC must be 12 digits'),
  release_date: Yup.string().required('Release date is required'),
  artwork_id: Yup.string().required('Artwork is required')
});

const CreateReleaseStep2Page = function () {
  const { releaseId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [editedTrack, setEditedTrack] = useState<Track>({});
  const [showTrackUpdateModal, setShowTrackUpdateModal] = useState(false);
  const [showUploadCreateTrackModal, setShowUploadCreateTrackModal] = useState(false);
  const queryClient = useQueryClient();

  const { data: release } = useQuery(
    ['release', releaseId],
    async () => {
      const { data: release } = await getRelease(releaseId!);
      return release;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const {
    data: releaseTracks,
    isFetching,
    refetch: refetchTracks
  } = useQuery(
    ['releaseTracks', releaseId],
    async () => {
      const { data: releaseTracks } = await getReleaseTracks(releaseId!);
      return releaseTracks;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const releaseTracksMutation = useMutation({
    mutationFn: async (updatedTracksOrder: Track[]) => {
      // Update the track number
      const updatedTracks = updatedTracksOrder.map((track, index) => {
        return { ...track, track_number: index + 1 };
      });

      // Patch each track with new track number
      for (const track of updatedTracks) {
        updateTrack({
          track_number: track.track_number,
          id: track.id!
        }).then(() => {});
      }

      return updatedTracks;
    },
    onSuccess: (data) => {
      console.info('Patching releases tracks with data: ', data);
      queryClient.setQueryData(['releaseTracks', `${releaseId}`], {
        results: data,
        count: data.length
      });
    }
  });

  const onDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        console.info('Index & destination are the same. Nothing to do.');
        return;
      }

      if (releaseTracks) {
        // Move the item in the array
        const reordered = Array.from(releaseTracks.results);

        if (reordered.length < 2) {
          console.info('Reorder not possible. There is only one track.');
          return;
        }

        const [removed] = reordered.splice(result.source.index, 1);
        reordered.splice(result.destination.index, 0, removed);
        releaseTracksMutation.mutate(reordered);
      }
    },
    [releaseTracks, releaseTracksMutation]
  );

  async function addTrack() {
    if (release && releaseTracks) {
      await createTrack(release.id!, {
        name: 'New track #' + (releaseTracks.count + 1),
        track_number: releaseTracks.count + 1
      });

      refetchTracks();
    }
  }

  async function removeTrack(trackId: string) {
    await deleteTrack(trackId);
    refetchTracks();
  }

  return (
    <>
      <div className="row">
        <div className="col text-end">
          <button
            className="btn btn-primary"
            onClick={async () => {
              await addTrack();
            }}>
            <i className="la la-plus"></i> Create new track{' '}
          </button>
          <button
            className="btn ms-5 btn-primary"
            onClick={() => {
              setShowUploadCreateTrackModal(true);
            }}>
            <i className="la la-upload"></i> Upload new track{' '}
          </button>
        </div>
      </div>
      {isFetching && (
        <div className="row mt-10">
          <div className="col text-center">
            <div className="spinner spinner-primary spinner-lg"></div>
          </div>
        </div>
      )}
      {!isFetching && releaseTracks && releaseTracks.count == 0 && (
        <div className="row mt-10">
          <div className="col text-center">
            <h4>There is no track on this release yet</h4>
            <span className="fw-bolder fst-italic">
              Click on "Create a new track" or "Upload a new track" to add one !
            </span>
          </div>
        </div>
      )}
      {!isFetching && releaseTracks && releaseTracks.count > 0 && (
        <div className="row mt-15">
          <div className="col col-md-1">
            <span>#</span>
          </div>
          <div className="col col-md-3">
            <span>Title</span>
          </div>
          <div className="col col-md-3">
            <span>Artists</span>
          </div>
          <div className="col col-md-2">
            <span>ISRC</span>
          </div>
          <div className="col col-md-3 text-end">
            <span>Actions</span>
          </div>
        </div>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-release-create-tracks">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {releaseTracks &&
                releaseTracks.results.map((track, index) => {
                  return (
                    <Draggable key={track.id} draggableId={index + '-' + track.id} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            className="row mt-5 align-items-center"
                            key={track.id!}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <div className="col col-md-1">
                              <span>#{track.track_number}</span>
                            </div>
                            <div className="col col-md-3">
                              <span>{track.name}</span>
                            </div>
                            <div className="col col-md-3">
                              <span>
                                <DisplayArtistName artists={track.artists} />
                              </span>
                            </div>
                            <div className="col col-md-2">
                              <span>{track.isrc ? track.isrc : '(not provided)'}</span>
                            </div>
                            <div className="col col-md-3 text-end">
                              <span>
                                <button
                                  className="btn btn-sm btn-icon btn-primary"
                                  onClick={() => {
                                    setEditedTrack(track);
                                    setShowTrackUpdateModal(true);
                                  }}>
                                  <i className="la la-edit"></i>
                                </button>
                                <button
                                  className="btn btn-sm btn-icon btn-danger ms-2"
                                  onClick={async () => {
                                    await removeTrack(track.id!);
                                  }}>
                                  <i className="la la-trash"></i>
                                </button>
                              </span>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="row mt-10">
        <div className="col text-center">
          <Link to={'/manager/catalog/releases/' + releaseId} className="btn btn-primary">
            Finish
          </Link>
        </div>
      </div>
      <TrackUpdateModal
        trackId={editedTrack.id!}
        show={showTrackUpdateModal}
        setShow={setShowTrackUpdateModal}
        onExit={() => {
          refetchTracks();
        }}
      />
      {release && (
        <UploadCreateTrackModal
          releaseId={release.id!}
          trackNumber={releaseTracks ? releaseTracks.count + 1 : 1}
          show={showUploadCreateTrackModal}
          setShow={setShowUploadCreateTrackModal}
          onExit={() => {
            refetchTracks();
          }}
        />
      )}
    </>
  );
};

export default CreateReleaseStep2Page;
