import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  addReleaseMedias,
  getReleaseMedias,
  getReleaseTracks
} from '../../../../modules/catalog/core/_requests';
import { DisplayArtistName } from '../../../../modules/catalog/artist/DisplayArtistName';

import Skeleton from 'react-loading-skeleton';
import { downloadMediaId } from '../../../../modules/medias/utils';
import { DateTime } from 'luxon';
import { Dropzone } from '../../../../modules/medias/Dropzone';
import { Media } from '../../../../modules/medias/core/_models';
import { deleteMedia, patchMedia } from '../../../../modules/medias/core/_requests';
import { humanFileSize } from '../../../../generics/utils';

const ViewReleaseFileManagerPage = function () {
  const { releaseId } = useParams();
  const queryClient = useQueryClient();

  const { data: releaseTracks, isFetching } = useQuery(
    ['releaseTracks', releaseId],
    async () => {
      const { data: result } = await getReleaseTracks(releaseId!);
      return result;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: releaseId !== undefined
    }
  );

  const {
    data: releaseMedias,
    isFetching: isFetchingMedias,
    refetch
  } = useQuery(
    ['releaseMedias', releaseId],
    async () => {
      const { data: result } = await getReleaseMedias(releaseId!);
      return result;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      enabled: releaseId !== undefined
    }
  );

  const mediaMutate = useMutation({
    mutationFn: async (updatedMedia: Partial<Media>) => {
      const { data: track } = await patchMedia(updatedMedia.id!, updatedMedia);
      return track;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('releaseMedias');
    }
  });

  async function removeMedia(mediaId: string) {
    if (confirm('Are you sure you want to delete this file?') == true) {
      await deleteMedia(mediaId);
      refetch();
    }
  }

  async function addMedia(media: Media) {
    await addReleaseMedias(releaseId!, [media.id]);
    refetch();
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Upload new files</h3>
        </div>
        <div className="card-body border-top p-9">
          <div className="h-200px">
            <Dropzone
              addMedia={addMedia}
              onComplete={() => {
                console.log('complete');
                refetch();
              }}
              restrictions={{}}
              public={false}
            />
          </div>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-header">
          <h3 className="card-title">File manager</h3>
        </div>
        <div className="card-body border-top p-9">
          <p className="fs-2 fw-bolder">Release files</p>
          <div className="table-responsive">
            <table className="table table-hover table-rounded table-striped border gy-3 gs-3">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>Track</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {isFetching && (
                  <tr>
                    <td colSpan={2} className="text-center">
                      <Skeleton />
                    </td>
                  </tr>
                )}

                {!isFetching && releaseTracks?.results.length === 0 && (
                  <tr>
                    <td colSpan={2} className="text-center">
                      <p>No tracks in this release.</p>
                    </td>
                  </tr>
                )}
                {!isFetching &&
                  releaseTracks?.results.length &&
                  releaseTracks?.results.map((track, index) => (
                    <tr key={'track-' + track.id}>
                      <th scope="row">
                        #{track.track_number} - <DisplayArtistName artists={track.artists} /> -{' '}
                        {track.name}
                      </th>
                      <td>
                        {track.audio_master && (
                          <button
                            className="btn btn-sm btn-dark"
                            onClick={() => downloadMediaId(`${track.audio_master}`)}>
                            wav
                          </button>
                        )}
                        {track.audio_mp3 && (
                          <button
                            className="btn btn-sm btn-dark ms-3"
                            onClick={() => downloadMediaId(`${track.audio_mp3}`)}>
                            mp3
                          </button>
                        )}
                        {track.audio_master === null && track.audio_mp3 === null && (
                          <span className="font-italic text-center">no files for this track</span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <p className="fs-2 fw-bolder mt-4">Additional files</p>

          <div className="table-responsive">
            <table className="table table-hover table-rounded table-striped border gy-3 gs-3">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                  <th>Name</th>
                  <th>Artist access</th>
                  <th>Uploaded on</th>
                  <th>File size</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {releaseMedias?.results.length === 0 && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      <p>
                        No medias for this release.
                        <br />
                        You can upload one using the form at the top of this page.
                      </p>
                    </td>
                  </tr>
                )}
                {releaseMedias?.results.map((media, index) => (
                  <tr key={'release-media' + media.id}>
                    <td>{media.name}</td>
                    <td>
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="1"
                          checked={media.artist_access}
                          onChange={(e) => {
                            mediaMutate.mutate({
                              id: media.id,
                              artist_access: e.target.checked
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td>{DateTime.fromISO(media.created).toLocaleString()}</td>
                    <td>{humanFileSize(media.size)}</td>
                    <td>
                      <a
                        target="_blank"
                        onClick={() => downloadMediaId(`${media.id}`)}
                        className="btn btn-info btn-sm btn-icon me-3">
                        <i className="fa fa-download"></i>
                      </a>
                      <a
                        className="btn btn-danger btn-sm btn-icon"
                        onClick={() => removeMedia(`${media.id}`)}>
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewReleaseFileManagerPage;
