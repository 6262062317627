import axios from 'axios';
import { Invoice, Organization, UserInvitation } from './_models';
import { Paginated } from '../../../generics/_models';
import { UserModel } from '../../auth';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const GET_ORGANIZATION = `${API_URL}/api/organizations/`;
export const GET_USER_INVITATIONS = `${API_URL}/api/users-invitations/`;

export const GET_INVOICES = `${API_URL}/api/invoices/`;

export function getOrganizations() {
  return axios.get<Paginated<Organization>>(GET_ORGANIZATION + '?limit=100');
}

export function getOrganization(id: string) {
  return axios.get<Organization>(GET_ORGANIZATION + id + '/');
}

export function patchOrganization(id: string, data: Partial<Organization>) {
  return axios.patch<Organization>(GET_ORGANIZATION + id + '/', data);
}

export function getOrganizationInvoices(id: string) {
  return axios.get<Paginated<Invoice>>(GET_ORGANIZATION + id + '/invoices/');
}

export function getInvoiceURL(id: string) {
  return axios.get<{ url: string }>(GET_INVOICES + id + '/view_url/');
}

export function getInvoicePDFURL(id: string) {
  return axios.get<{ url: string }>(GET_INVOICES + id + '/pdf_url/');
}

export function getOrganizationUsers(id: string) {
  return axios.get<Paginated<UserModel>>(GET_ORGANIZATION + id + '/users/');
}

export function removeOrganizationUser(id: string, userId: string) {
  return axios.delete(GET_ORGANIZATION + id + '/users/' + userId + '/');
}

export function inviteUser(data: UserInvitation) {
  return axios.post(GET_USER_INVITATIONS, data);
}

export function getOrganizationSubscriptionPortalLink(id: string) {
  return axios.get<{ url: string }>(GET_ORGANIZATION + id + '/subscriptions/subscription_portal/');
}
