import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { postFanlinkSpotifyPresave } from '../../modules/fanlink/core/_requests';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const OAuthCallbackPage = function () {
  const { platform } = useParams();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    const originalState = localStorage.getItem(platform + '_oauth_state');
    const redirectURL = localStorage.getItem(platform + '_oauth_router_url');

    if (query.get('state') != originalState) {
      return navigate(redirectURL ? redirectURL : '/');
    }

    localStorage.removeItem(platform + '_oauth_state');
    localStorage.removeItem(platform + '_oauth_router_url');

    const code = query.get('code');
    navigate({ pathname: redirectURL ? redirectURL : '/', search: '?spotify_code=' + code });
  }, [query, platform, navigate]);

  return <></>;
};

export default OAuthCallbackPage;
