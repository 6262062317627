import { useQuery, useQueryClient } from 'react-query';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Content } from '../../../../_metronic/layout/components/Content';
import { getOrganizations } from '../../../modules/organization/core/_requests';
import { Organization } from '../../../modules/organization/core/_models';
import { useOrganization } from '../../../modules/organization/core/Organization';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ChooseOrganizationPage = function () {
  const queryClient = useQueryClient();
  const { currentOrganization, setCurrentOrganization } = useOrganization();
  const navigate = useNavigate();

  const { data: organizations } = useQuery(
    ['organizations'],
    async () => {
      const { data: organizations } = await getOrganizations();
      return organizations;
    },
    { refetchOnWindowFocus: false, staleTime: 300 }
  );

  useEffect(() => {
    document.body.style.backgroundImage = `none`;
  }, []);

  const chooseOrganization = (organization: Organization) => {
    setCurrentOrganization(organization);
    window.localStorage.setItem('lm-organization-id', organization.id?.toString() || '');
    navigate('/manager/dashboard');
  };

  return (
    <>
      <div className="content d-flex flex-column flex-column-fluid align-items-center">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="text-center mb-10">
                <img
                  className="h-50px"
                  src={toAbsoluteUrl('media/logos/lm/logo-text-black.png')}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="text-center mb-10 text-muted">
                <h1>Please choose the organization you want to access :</h1>
              </div>
            </div>
          </div>

          {organizations &&
            organizations.results.map((organization: Organization) => (
              <div className="row mb-5" key={organization.id}>
                <div className="col text-center">
                  <button
                    onClick={() => chooseOrganization(organization)}
                    className="btn btn-flex btn-outline btn-outline-default p-7">
                    <KTIcon iconName="home-1" className="fs-1" />
                    <span className="d-flex flex-column align-items-start ms-4">
                      <span className="fs-3">{organization.name}</span>
                    </span>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export { ChooseOrganizationPage };
