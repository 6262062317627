import axios from 'axios';
import { Media, UploadMedia } from './_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const MEDIA_URL = `${API_URL}/api/medias/`;

export function createMedia(filename: string, content_type: string | undefined, public_: boolean) {
  return axios.post<UploadMedia>(MEDIA_URL, {
    name: filename,
    content_type: content_type,
    public: public_
  });
}

export function getMedia(id: string) {
  return axios.get<Media>(MEDIA_URL + id + '/');
}

export function confirmMediaUpload(id: string) {
  return axios.post<Media>(MEDIA_URL + id + '/confirm_upload/', {});
}

export function updateMedia(id: string, props: Media) {
  return axios.put<Media>(MEDIA_URL + id + '/', props);
}

export function patchMedia(id: string, props: Partial<Media>) {
  return axios.patch<Media>(MEDIA_URL + id + '/', props);
}

export function deleteMedia(id: string) {
  return axios.delete(MEDIA_URL + id + '/');
}
