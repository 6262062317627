import axios from 'axios';

const API_URL = import.meta.env.VITE_APP_API_URL;

export const MARKETING_URL = `${API_URL}/api/marketing/`;

export function confirmOptin(optinToken: string) {
  return axios.post(
    MARKETING_URL + 'optin/',
    {},
    { params: { confirmation_token: optinToken } }
  );
}
