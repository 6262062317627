import { useCallback } from 'react';
import { Artist, ReleaseFanlinkPlatformLink, ReleaseFanlinkStoreLink } from '../core/_models';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { getPlatfomData } from './utils';

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? 'var(--bs-light)' : 'none'
});

function DragDropPlatformLinks(props: {
  links: ReleaseFanlinkPlatformLink[];
  setLinks: (links: ReleaseFanlinkPlatformLink[]) => void;
}) {
  const onDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        console.info('Reordering empty list. Nothing to do.');
        return;
      }

      // Move the item in the array
      const reordered = Array.from(props.links);

      if (reordered.length < 2) {
        console.info('Reordering empty list. Nothing to do.');
        return;
      }

      const [removed] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, removed);
      props.setLinks(reordered);
    },
    [props]
  );

  const enabledChange = useCallback(
    (index: number) => {
      props.links[index].enabled = !props.links[index].enabled;
      props.setLinks(props.links);
    },
    [props]
  );

  const onLinkChange = useCallback(
    (index: number, value: string) => {
      props.links[index].link = value;
      props.setLinks(props.links);
    },
    [props]
  );

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {props.links.map((link, index) => {
              return (
                <Draggable key={link.platform} draggableId={index + '-' + link.platform} index={index}>
                  {(provided, snapshot) => {
                    const platformData = getPlatfomData(link.platform);
                    return (
                      <div
                        className="row d-flex flex-row align-items-center mb-10 mt-10"
                        style={{
                          boxShadow: snapshot.isDragging
                            ? '0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important'
                            : 'none'
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className="col-1 text-center">
                          <KTIcon iconName="abstract-14" className="fs-1"></KTIcon>
                        </div>
                        <div className="col-2 text-center">
                          <img
                            src={toAbsoluteUrl(
                              `media/landing_providers/${platformData?.logoBlack}`
                            )}
                            alt={platformData?.name}
                            className="img-fluid h-35px"
                          />
                        </div>
                        <div className="col-7 fw-bolder fs-4">
                          <span>
                            <div className="input-group input-group-lg input-group-solid">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="https://www.my-url..."
                                value={link.link}
                                onChange={(e) => {
                                  onLinkChange(index, e.target.value);
                                }}
                              />
                              <a href={link.link} target="_blank" className="btn btn-primary">
                                <KTIcon iconName="exit-right-corner" className="fs-3" />
                              </a>
                            </div>
                          </span>
                        </div>
                        <div className="col-2 text-end">
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={link.enabled}
                              onChange={() => enabledChange(index)}
                            />
                            <label className="form-check-label">Enabled</label>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragDropPlatformLinks;
