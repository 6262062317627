import axios from 'axios';
import { SearchResult } from './_models';

const API_URL = import.meta.env.VITE_APP_API_URL;

const SEARCH_URL = `${API_URL}/api/search/`;

export function getSearch(query: string) {
  return axios.get<SearchResult>(SEARCH_URL + '?q=' + query);
}
