import 'react-bootstrap-typeahead/css/Typeahead.css';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Artist, ArtistSearchResult } from '../core/_models';
import { useRef, useState } from 'react';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { createArtist, searchArtist } from '../core/_requests';

function ArtistTypeahead(props: { addArtist: (artist: Artist) => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  // @ts-expect-error Typeahead ref
  const ref = useRef<Typeahead>(null);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    const artists = await searchArtist(query);
    setOptions(artists.data);
    setIsLoading(false);
  };

  const filterBy = () => true;

  const onChange = async (selected: { customOption: boolean; name: string }[]) => {
    if (selected.length === 1) {
      const item = selected[0];
      let selectedArtist: Artist;
      if (item.customOption === true) {
        selectedArtist = (await createArtist(item.name)).data;
      } else {
        selectedArtist = item as unknown as Artist;
      }
      props.addArtist(selectedArtist);
      setOptions([]);
      ref.current?.clear();
    }
  };

  return (
    <AsyncTypeahead
      ref={ref}
      allowNew={true}
      newSelectionPrefix="Add a new artist:"
      onChange={onChange as unknown as (selected: Option[]) => void}
      filterBy={filterBy}
      id="artist-typeahead"
      isLoading={isLoading}
      minLength={2}
      onSearch={handleSearch}
      options={options}
      labelKey="name"
      placeholder="Search artists ..."
      renderMenuItemChildren={(option: Option) => {
        return (
          <>
            <span>{(option as ArtistSearchResult).name}</span>
          </>
        );
      }}
    />
  );
}

export { ArtistTypeahead };
