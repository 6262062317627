import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  getFanlinkPlatforms,
  getRelease,
  getReleaseFanlinks,
  updateFanlink,
  updateFanlinkPlatforms
} from '../../../../modules/catalog/core/_requests';
import Flatpickr from 'react-flatpickr';
import {
  Release,
  ReleaseFanlink,
  ReleaseFanlinkPlatformLink
} from '../../../../modules/catalog/core/_models';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useCallback, useDebugValue, useEffect, useState } from 'react';
import DragDropPlatformLinks from '../../../../modules/catalog/generics/DragDropPlatformLinks';
import { getPlatfomData } from '../../../../modules/catalog/generics/utils';
import { StreamingPlatforms } from '../../../../generics/_models';
import Skeleton from 'react-loading-skeleton';
import '../../../../../_metronic/assets/sass/style.react.scss';
import { DateTime } from 'luxon';

const FanlinkUpdateSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Description is required'),
  slug: Yup.string().required('Slug is required'),
  presave_enabled: Yup.boolean(),
  email_registration_enabled: Yup.boolean(),
  presave_expiration: Yup.string()
});

const ViewReleaseFanlinkConfigurationPage = function () {
  const { releaseId } = useParams();
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const fanlinkUpdateMutation = useMutation({
    mutationFn: async (updatedFanlinkData) => {
      // @ts-expect-error - fanlink is not undefined
      const { data: fanlink } = await updateFanlink(updatedFanlinkData.id!, updatedFanlinkData!);
      return fanlink;
    },
    onSuccess: (data) => {
      console.info('Patching fanlink with data: ', data);
      queryClient.setQueryData(['releaseFanlink', `${data.id}`], data);
      setLoading(false);
      formik.setStatus(null);
    }
  });

  const { data: fanlink, isFetching } = useQuery(
    ['releaseFanlink', `${releaseId}`],
    async () => {
      const { data: result } = await getReleaseFanlinks(releaseId!);
      return result.results.length ? result.results[0] : null;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5
    }
  );

  function addMissingPlatforms(platforms: ReleaseFanlinkPlatformLink[]) {
    const missingPlatforms = StreamingPlatforms.platforms.filter(
      (platform) => !platforms.find((p) => p.platform == platform.key && platform.name !== 'other')
    );

    missingPlatforms.forEach((platform) => {
      platforms.push({
        platform: platform.key,
        enabled: false,
        link: ''
      });
    });

    return platforms;
  }

  const fanlinkPlatformsOptimisticMutation = useMutation({
    mutationFn: async (updatedFanlinkData) => {
      queryClient.setQueryData(['fanlinkPlatforms', `${fanlink?.id}`], updatedFanlinkData);
    }
  });

  const fanlinkPlatformsMutation = useMutation({
    mutationFn: async (updatedFanlinkData: { id: string; links: ReleaseFanlinkPlatformLink[] }) => {
      const platforms = updatedFanlinkData.links.filter((platform) => platform.link != '');
      const { data: result } = await updateFanlinkPlatforms(updatedFanlinkData.id!, platforms);
      return addMissingPlatforms(result.results);
    },
    onSuccess: (data) => {
      console.info('Patching fanlink platforms with data: ', data);
      queryClient.setQueryData(['fanlinkPlatforms', `${fanlink?.id}`], data);
      setLoading(false);
      formik.setStatus(null);
    }
  });

  const { data: fanlinkPlatforms } = useQuery(
    ['fanlinkPlatforms', `${fanlink?.id}`],
    async () => {
      if (!fanlink) {
        return null;
      }
      const { data: result } = await getFanlinkPlatforms(fanlink!.id!);
      return addMissingPlatforms(result.results);
    },
    { refetchOnWindowFocus: false }
  );

  const formik = useFormik<ReleaseFanlink>({
    initialValues: {
      id: fanlink?.id || '',
      title: fanlink?.title || '',
      description: fanlink?.description || '',
      slug: fanlink?.slug || '',
      presave_enabled: fanlink?.presave_enabled || false,
      email_registration_enabled: fanlink?.email_registration_enabled || false,
      presave_expiration: fanlink?.presave_expiration || ''
    },
    validationSchema: FanlinkUpdateSchema,
    onSubmit: async (values) => {
      if (!fanlink) {
        return;
      }

      setLoading(true);
      try {
        // @ts-expect-error - mutate never works
        fanlinkUpdateMutation.mutate(values);
        fanlinkPlatformsMutation.mutate({ id: fanlink.id!, links: fanlinkPlatforms! });
      } catch (error) {
        console.error(error);
        formik.setStatus(
          'An error occured while updating the fanlink informations. Please try again later.'
        );
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      id: fanlink?.id || '',
      title: fanlink?.title || '',
      description: fanlink?.description || '',
      slug: fanlink?.slug || '',
      presave_enabled: fanlink?.presave_enabled || false,
      email_registration_enabled: fanlink?.email_registration_enabled || false,
      presave_expiration: fanlink?.presave_expiration || ''
    });
  }, [fanlink]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Informations</h3>
        </div>
        <div className="card-body border-top p-9">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            {formik.status && (
              <div className="row">
                <div className="col">
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-2">
              <div className="col">
                <h3>Fanlink informations:</h3>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Title:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Middle"
                        {...formik.getFieldProps('title')}
                      />
                    </div>
                    {formik.touched.title && formik.errors.title && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.title}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Description:
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <textarea
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Middle"
                        {...formik.getFieldProps('description')}></textarea>
                    </div>
                    {formik.touched.description && formik.errors.description && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.description}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-2">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Link:</label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <span className="input-group-text" id="basic-addon2">
                        {fanlink?.short_domain}
                        {fanlink?.organization_slug}
                      </span>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="artist-my-track"
                        {...formik.getFieldProps('slug')}
                      />
                    </div>
                    {formik.touched.slug && formik.errors.slug && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.slug}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mb-2 align-items-center d-flex">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Presave links enabled :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formik.values.presave_enabled}
                          {...formik.getFieldProps('presave_enabled')}
                        />
                      </div>
                    </div>
                    {formik.touched.presave_enabled && formik.errors.presave_enabled && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.presave_enabled}</div>
                      </div>
                    )}
                  </div>
                </div>

                {formik.values.presave_enabled && (
                  <div className="row mb-2 align-items-center d-flex">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Pre-save link expiration date:
                    </label>
                    <div className="col-lg-8">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="form-check form-switch form-check-custom form-check-solid">
                          <Flatpickr
                            value={formik.values.presave_expiration}
                            onChange={([date1]: Date[]) => {
                              formik.setFieldValue(
                                'presave_expiration',
                                DateTime.fromJSDate(date1).toISO()
                              );
                            }}
                            className="form-control"
                            placeholder="Pick date"
                          />
                        </div>
                      </div>
                      {formik.touched.presave_expiration && formik.errors.presave_expiration && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{formik.errors.presave_expiration}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="row mb-2 align-items-center">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Email registration enabled :
                  </label>
                  <div className="col-lg-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="form-check form-switch form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={formik.values.email_registration_enabled}
                          {...formik.getFieldProps('email_registration_enabled')}
                        />
                      </div>
                    </div>
                    {formik.touched.email_registration_enabled &&
                      formik.errors.email_registration_enabled && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.email_registration_enabled}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col">
                <div className="row mt-4">
                  <div className="col">
                    <h3>Platform links</h3>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col">
                    {fanlink && fanlinkPlatforms ? (
                      <DragDropPlatformLinks
                        links={fanlinkPlatforms}
                        setLinks={(links) => {
                          // @ts-expect-error - links is not undefined
                          fanlinkPlatformsOptimisticMutation.mutate(links);
                        }}
                      />
                    ) : (
                      <Skeleton count={6} width={'100%'} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-10">
              <div className="col text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formik.isSubmitting || !formik.isValid || loading}>
                  {!loading && <span className="indicator-label">Update fanlink informations</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ViewReleaseFanlinkConfigurationPage;
