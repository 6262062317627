import { useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = `none`;
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      document.body.style.backgroundImage = `url(${toAbsoluteUrl('media/patterns/header-bg.jpg')})`;
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <img
            alt="Logo"
            src={toAbsoluteUrl('media/logos/lm/logo-text-black.png')}
            className="logo-default h-60px mb-10"
          />
          {/* begin::Wrapper */}
          <div className="w-lg-500px p-10">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className="d-flex flex-center flex-wrap px-5">
          {/* begin::Links */}
          {/* <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div> */}
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        style={{ backgroundImage: `url(${toAbsoluteUrl('media/misc/auth-bg.png')})` }}>
        {/* begin::Content */}
        <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
          {/* <Link to="/" className="mb-12">
            <img alt="Logo" src={toAbsoluteUrl('media/logos/custom-1.png')} className="h-75px" />
          </Link> */}

          <img
            className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
            src={toAbsoluteUrl('media/misc/product.png')}
            alt=""
          />

          <h1 className="text-white fs-2qx fw-bolder text-center mb-7">
            Manage everything about your record label
          </h1>

          <div className="text-white fs-base text-center">
            <p>
              labelmanager.io is a complete solution to manage everything about your record label on
              a daily basis.
            </p>
            <p>
              From catalog management, marketing, promotion to royalties accounting and analytics,
              you can do it all in one place.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AuthLayout };
