import { useParams } from 'react-router-dom';
import { ReleaseFanlinkStatsCard } from '../../../../modules/catalog/release/ReleaseFanlinkStatsCard';
import { getReleaseFanlinks } from '../../../../modules/catalog/core/_requests';
import { useQuery } from 'react-query';

const ViewReleaseOverviewPage = function () {
  const { releaseId } = useParams();

  const { data: fanlink, isFetching } = useQuery(
    ['releaseFanlink', `${releaseId}`],
    async () => {
      const { data: result } = await getReleaseFanlinks(releaseId!);
      return result.results.length ? result.results[0] : null;
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60 * 5
    }
  );

  return <>{fanlink && <ReleaseFanlinkStatsCard fanlinkId={fanlink.id!} />}</>;
};

export default ViewReleaseOverviewPage;
