import { useCallback, useEffect, useState } from 'react';
import { UserEditModalHeader } from '../../apps/user-management/users-list/user-edit-modal/UserEditModalHeader';
import { KTIcon } from '../../../../_metronic/helpers';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Track } from './core/_models';
import { DisplayArtistName } from '../artist/DisplayArtistName';
import { removeEmpty } from '../generics/utils';
import { getTrack, updateTrack } from './core/_requests';
import { ArtistTypeahead } from '../artist/ArtistTypeahead';
import DragDropArtistList from '../artist/DragDropArtistList';
import { Artist } from '../core/_models';
import { Dropzone } from '../../medias/Dropzone';
import { deleteMedia } from '../../medias/core/_requests';
import { Media } from '../../medias/core/_models';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { capitalize } from '../../../generics/utils';

const TrackUpdateSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  version: Yup.string().required('Version is required'),
  isrc: Yup.string().length(12, 'ISRC must be 12 characters'),
  copyright_year: Yup.number(),
  copyright_owner: Yup.string()
});

const TrackUpdateModal = (props: {
  show: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShow: any;
  trackId: string;
  onExit?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [trackArtists, setTrackArtists] = useState<Artist[]>([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: track } = useQuery(
    ['track', `${props.trackId}`],
    async () => {
      const { data: track } = await getTrack(props.trackId);
      setTrackArtists(track.artists!);
      return track;
    },
    {
      refetchOnWindowFocus: false,
      enabled: props.trackId !== undefined
    }
  );

  const trackUpdateMutation = useMutation({
    mutationFn: async (updatedTrackData: Track) => {
      const { data: track } = await updateTrack(updatedTrackData);
      return track;
    },
    onSuccess: (data) => {
      console.info('Patching track with data: ', data);
      queryClient.setQueryData(['track', `${data.id}`], data);
      setLoading(false);
      formik.setStatus(null);
    }
  });

  const formik = useFormik<Track>({
    initialValues: {
      name: track?.name || '',
      isrc: track?.isrc || '',
      version: track?.version || 'original',
      track_number: track?.track_number || 0,
      copyright_owner: track?.copyright_owner || '',
      copyright_year: track?.copyright_year || '',
      explicit: track?.explicit || false,
      live_version: track?.live_version || false,
      cover_version: track?.cover_version || false,
      medley: track?.medley || false
    },
    validationSchema: TrackUpdateSchema,
    onSubmit: async (values) => {
      try {
        if (track) {
          setLoading(true);

          // First we update the track
          await trackUpdateMutation.mutateAsync({
            id: track.id!,
            ...removeEmpty(values),
            artists: trackArtists
          });

          props.setShow(false);
        }
      } catch (error) {
        console.error(error);
        formik.setStatus(
          'An error occured while updating the track. Please try again. Our team has been notified.'
        );
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (track) {
      formik.setValues({
        name: track.name || '',
        isrc: track.isrc || '',
        version: track.version || 'original',
        track_number: track.track_number || 0,
        explicit: track?.explicit || false,
        live_version: track?.live_version || false,
        cover_version: track?.cover_version || false,
        medley: track?.medley || false,
        copyright_owner: track?.copyright_owner || '',
        copyright_year: track?.copyright_year || ''
      });
      setTrackArtists(track.artists || []);
    }
  }, [track]);

  const addArtist = useCallback(
    (artist: Artist) => {
      setTrackArtists([...trackArtists, artist]);
    },
    [trackArtists]
  );

  function addMaster(media: Media) {
    if (track) {
      updateTrack({ id: track?.id, audio_master_id: media.id });
      trackUpdateMutation.mutate({
        id: track.id,
        audio_master_id: media.id
      });
    }
  }

  async function deleteMasterRecording() {
    if (track) {
      if (track.audio_master) {
        await deleteMedia(`${track.audio_master}`);
      }

      if (track.audio_mp3) {
        await deleteMedia(`${track.audio_mp3}`);
      }

      trackUpdateMutation.mutate({
        id: track.id,
        audio_master: null,
        audio_mp3: null
      });
    }
  }

  return (
    <>
      {track && (
        <Modal
          show={track && props.show}
          fullscreen={true}
          onExit={props.onExit}
          className="modal-fullscreen">
          <Modal.Header>
            <Modal.Title>
              <>
                <DisplayArtistName artists={track.artists} />
                {' - ' + track.name}
              </>
            </Modal.Title>
            <div
              className="btn btn-icon btn-sm btn-active-icon-primary"
              data-kt-users-modal-action="close"
              onClick={() => props.setShow(false)}
              style={{ cursor: 'pointer' }}>
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="informations" className="mb-3 nav-line-tabs" fill={true}>
              <Tab
                eventKey="informations"
                title="Track informations"
                className="nav-item"
                tabClassName="fw-bolder">
                <form onSubmit={formik.handleSubmit} noValidate className="form mt-10">
                  {formik.status && (
                    <div className="row">
                      <div className="col">
                        <div className="mb-lg-15 alert alert-danger">
                          <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row mt-10 mb-5">
                    <div className="col fw-bolder">
                      <span className="fs-4">Basic informations:</span>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col">
                      <div className="row mb-5">
                        <div className="col">
                          <label className="col-2 col-form-label required fw-bold fs-6">
                            Title:
                          </label>
                          <div className="col-10">
                            <div className="input-group input-group-lg input-group-solid">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Middle"
                                {...formik.getFieldProps('name')}
                              />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.name}</div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col">
                          <label className="col-2 col-form-label required fw-bold fs-6">
                            Version:
                          </label>
                          <div className="col-10">
                            <div className="input-group input-group-lg input-group-solid">
                              <Select
                                className="react-select-solid react-select-styled react-select-lg w-100"
                                classNamePrefix="react-select"
                                defaultValue={{
                                  value: track.version,
                                  label: capitalize(track.version)
                                }}
                                isSearchable={false}
                                options={[
                                  { value: 'original', label: 'Original' },
                                  // { value: 'remix', label: 'Remix' },
                                  // { value: 'acoustic', label: 'Acoustic' },
                                  // { value: 'live', label: 'Live' },
                                  // { value: 'radio_edit', label: 'Radio edit' },
                                  { value: 'extended', label: 'Extended' }
                                  // { value: 'instrumental', label: 'Instrumental' },
                                  // { value: 'karaoke', label: 'Karaoke' },
                                  // { value: 'other', label: 'Other' }
                                ]}
                                placeholder="Select an option"
                                onChange={(option) =>
                                  formik.setFieldValue('version', option?.value)
                                }
                              />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.version}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col">
                          <label className="col-form-label fw-bold fs-6">
                            &copy; Copyright year:
                          </label>
                          <div className="input-group input-group-lg input-group-solid">
                            <input
                              type="number"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="2024"
                              {...formik.getFieldProps('copyright_year')}
                            />
                          </div>
                          {formik.touched.copyright_year && formik.errors.copyright_year && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.copyright_year}</div>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <label className="col-form-label fw-bold fs-6">
                            &copy; Copyright owner:
                          </label>
                          <div className="input-group input-group-lg input-group-solid">
                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Label Manager Records inc."
                              {...formik.getFieldProps('copyright_owner')}
                            />
                          </div>
                          {formik.touched.copyright_owner && formik.errors.copyright_owner && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.copyright_owner}</div>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <label className="col-form-label fw-bold fs-6">ISRC</label>
                          <div className="input-group input-group-lg input-group-solid">
                            <input
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="FRXXXXXXXX"
                              {...formik.getFieldProps('isrc')}
                            />
                          </div>
                          {formik.touched.isrc && formik.errors.isrc && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.isrc}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mt-10 mb-5">
                        <div className="col fw-bolder">
                          <span className="fs-4">Other track attributes:</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-10 d-flex">
                            <div className="form-check form-check-custom form-check-solid m-auto">
                              <input
                                className="form-check-input h-30px w-30px"
                                type="checkbox"
                                {...formik.getFieldProps('explicit')}
                              />
                              <label className="form-check-label">Explicit track</label>
                            </div>
                          </div>
                          {formik.touched.explicit && formik.errors.explicit && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.explicit}</div>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <div className="mb-10 d-flex">
                            <div className="form-check form-check-custom form-check-solid m-auto">
                              <input
                                className="form-check-input h-30px w-30px"
                                type="checkbox"
                                {...formik.getFieldProps('live_version')}
                              />
                              <label className="form-check-label">Live version</label>
                            </div>
                          </div>
                          {formik.touched.live_version && formik.errors.live_version && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.live_version}</div>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <div className="mb-10 d-flex">
                            <div className="form-check form-check-custom form-check-solid m-auto">
                              <input
                                className="form-check-input h-30px w-30px"
                                type="checkbox"
                                {...formik.getFieldProps('cover_version')}
                              />
                              <label className="form-check-label">Cover version</label>
                            </div>
                          </div>
                          {formik.touched.cover_version && formik.errors.cover_version && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.cover_version}</div>
                            </div>
                          )}
                        </div>
                        <div className="col">
                          <div className="mb-10 d-flex">
                            <div className="form-check form-check-custom form-check-solid m-auto">
                              <input
                                className="form-check-input h-30px w-30px"
                                type="checkbox"
                                {...formik.getFieldProps('medley')}
                              />
                              <label className="form-check-label">Medley</label>
                            </div>
                          </div>
                          {formik.touched.medley && formik.errors.medley && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.medley}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Tab>
              <Tab eventKey="artists" title="Track artists" tabClassName="fw-bolder">
                <div className="row mt-6">
                  <div className="col">
                    {track && track.artists && (
                      <>
                        <div className="row mt-4">
                          <div className="col">
                            <h3>Artists</h3>
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col">
                            <p className="mb-2 fw-bolder">Search artist to add one:</p>
                            <ArtistTypeahead addArtist={addArtist} />
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col">
                            <p className="mb-2 fw-bolder">Current artist list:</p>
                            <DragDropArtistList
                              artists={trackArtists}
                              setArtists={setTrackArtists}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="audio" title="Audio" tabClassName="fw-bolder">
                {track.audio_master == null ? (
                  <>
                    <div className="row">
                      <div className="mt-5">
                        <div className="col text-center">
                          <span className="fs-3">
                            Upload your master recording, by dropping your file here:
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col h-400px">
                        <Dropzone
                          addMedia={addMaster}
                          restrictions={{
                            maxNumberOfFiles: 1,
                            allowedFileTypes: ['.wav', 'audio/x-wav']
                          }}
                          public={false}
                        />
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col text-center">
                        <span className="text-muted">
                          Recommended settings : WAV - Frequency: 44.1 - 96.0kHz. Bitrate: 16 - 24
                          bit
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-5 mb-5">
                      <div className="col text-center">
                        <p className="fw-bolder">Your master recording is already uploaded.</p>
                        <button
                          className="mt-2 btn btn-danger"
                          onClick={() => deleteMasterRecording()}>
                          <i className="la la-trash"></i>
                          Delete file
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => formik.handleSubmit()}
              disabled={formik.isSubmitting || !formik.isValid}>
              {loading && <span className="spinner spinner-white spinner-sm spinner-left"></span>}
              Save changes
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export { TrackUpdateModal };
