import { useCallback } from 'react';
import { Artist } from '../core/_models';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { KTIcon } from '../../../../_metronic/helpers';

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? 'var(--bs-light)' : 'none'
});

function DragDropArtistList(props: { artists: Artist[]; setArtists: (artists: Artist[]) => void }) {
  const onDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        console.info('Reordering empty list. Nothing to do.');
        return;
      }

      // Move the item in the array
      const reordered = Array.from(props.artists);

      if (reordered.length < 2) {
        console.info('Reordering empty list. Nothing to do.');
        return;
      }

      const [removed] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination.index, 0, removed);
      props.setArtists(reordered);
    },
    [props]
  );

  const removeArtist = (index: number) => {
    const artists = Array.from(props.artists);
    artists.splice(index, 1);
    props.setArtists(artists);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return props.artists.length === 0 ? (
    <>
      <div className="row text-center fst-italic border rounded-3 mb-3 mt-3 bg-light">
        <span className="m-5">There is currently no artists.</span>
      </div>
    </>
  ) : (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}>
              {props.artists.map((artist, index) => {
                return (
                  <Draggable
                    key={artist.id}
                    draggableId={index + '-' + artist.id!.toString()}
                    index={index}>
                    {(provided, snapshot) => (
                      <div
                        className="row d-flex flex-row align-items-center border rounded-3 mb-3 mt-3 bg-light"
                        style={{
                          boxShadow: snapshot.isDragging
                            ? '0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important'
                            : 'none'
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div className="col-2">
                          <KTIcon iconName={'abstract-14'} className="fs-1 m-5" />
                        </div>
                        <div className="col-8 fw-bolder fs-4">
                          <span className="m-5">{artist.name}</span>
                        </div>
                        <div className="col-2 text-end">
                          <button
                            className="btn btn-sm btn-icon btn-danger"
                            onClick={() => {
                              removeArtist(index);
                            }}>
                            <KTIcon iconName={'trash'} className="fs-1 m-5" />
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default DragDropArtistList;
