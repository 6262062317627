import { Link, Outlet, useLocation } from 'react-router-dom';
import { Content } from '../../../../_metronic/layout/components/Content';
import { Toolbar } from '../../../../_metronic/layout/components/toolbar/Toolbar';
import { PageTitle } from '../../../../_metronic/layout/core';
import { KTIcon, checkIsActive } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';

const AccountSettingsWrapper = function () {
  const { currentUser, logout } = useAuth();
  const location = useLocation();

  return (
    <>
      <PageTitle breadcrumbs={[]}>Personal settings</PageTitle>
      <Toolbar />
      <Content>
        <div className="row">
          <div className="col-xs-12 col-md-4 col-xl-3 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-shrink-0 me-7">
                    <div className="symbol symbol-50px symbol-lg-120">
                      <div className="symbol-label bg-light">
                        {currentUser!.first_name[0] + currentUser!.last_name[0]}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <a href="#" className="text-gray-800 text-hover-primary fs-6 fw-bolder mb-2">
                      {currentUser?.first_name} {currentUser?.last_name}
                    </a>
                  </div>
                </div>
                {/* Vertical tab navigation */}
                <ul className="mt-10 nav nav-tabs nav-pills flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/account/informations'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/account/informations')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="profile-circle" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">Account informations</div>
                    </Link>
                  </li>
                  <li className="nav-item w-100 me-0 mb-md-4">
                    <Link
                      to={'/manager/account/security'}
                      className={
                        'nav-link w-100 btn btn-flex ' +
                        (checkIsActive(location.pathname, '/manager/account/security')
                          ? 'btn-light-primary'
                          : 'btn-bg-light btn-color-muted')
                      }>
                      <KTIcon iconName="lock-3" className="fs-1 me-3" />
                      <div className="fs-4 fw-bold w-100 text-center">Account security</div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col mt-xs-3">
            <Outlet />
          </div>
        </div>
      </Content>
    </>
  );
};

export default AccountSettingsWrapper;
