import { useInfiniteQuery, useQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PageTitle } from '../../../../../_metronic/layout/core';
import { Toolbar } from '../../../../../_metronic/layout/components/toolbar/Toolbar';
import { Content } from '../../../../../_metronic/layout/components/Content';

import {
  getRelease,
  getReleaseFanlinks,
  getReleaseStoreLinks
} from '../../../../modules/catalog/core/_requests';
import { DisplayArtistName } from '../../../../modules/catalog/artist/DisplayArtistName';
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { Dropdown1 } from '../../../../../_metronic/partials';
import { LoadingReleaseHeader } from '../../../../modules/catalog/release/LoadingReleaseHeader';
import StoreLinkButtons from '../../../../modules/catalog/release/StoreLinkButtons';
import { ReleaseUpcomingBadge } from '../../../../modules/catalog/release/ReleaseUpcomingBadge';

const ViewReleaseWrapper = function () {
  const { releaseId } = useParams();

  const { data: release, isFetching } = useQuery(
    ['release', releaseId],
    async () => {
      const { data: result } = await getRelease(releaseId!);
      return result;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  const { data: fanlink } = useQuery(
    ['releaseFanlink', releaseId],
    async () => {
      const { data: result } = await getReleaseFanlinks(releaseId!);
      return result.results[0];
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  const { data: storeLinks } = useQuery(
    ['releaseStoreLinks', releaseId],
    async () => {
      const { data: result } = await getReleaseStoreLinks(releaseId!);
      return result;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5
    }
  );

  return (
    <>
      <PageTitle breadcrumbs={[]}>Release Catalog</PageTitle>
      <Toolbar />
      <Content>
        {(release && !isFetching && (
          <>
            <div className="card mb-5 mb-xl-10">
              <div className="card-body pt-9 pb-0">
                <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                  <div className="me-7 mb-4">
                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                      <img src={release.artwork?.public_url} alt={release.name} />
                      {/* <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div> */}
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                          <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                            Release: <DisplayArtistName artists={release.artists} />
                            {' — '}
                            {release.name}
                          </span>
                          {/* <a href="#">
                        <KTIcon iconName="verify" className="fs-1 text-primary" />
                      </a> */}
                        </div>

                        <div className="d-flex flex-wrap fw-bold fs-6 mb-2 pe-2">
                          {release.upc && (
                            <span className="d-flex align-items-center text-gray-500 me-5">
                              <KTIcon iconName="barcode" className="fs-4 me-1" />
                              UPC: {release?.upc}
                            </span>
                          )}
                          {release.catalog_number && (
                            <span className="d-flex align-items-center text-gray-500 me-5">
                              <KTIcon iconName="information" className="fs-4 me-1" />
                              Catalog number:{' '}
                              <span className="badge badge-dark ms-3">
                                {release?.catalog_number}
                              </span>
                            </span>
                          )}
                          {release.release_date && (
                            <span className="d-flex align-items-center text-gray-500 me-5">
                              <KTIcon iconName="calendar-2" className="fs-4 me-1" />
                              Release date :{' '}
                              <span className="badge badge-primary ms-3">
                                {DateTime.fromISO(release!.release_date!).toLocaleString()}
                              </span>
                            </span>
                          )}
                          <ReleaseUpcomingBadge release={release} />
                        </div>
                      </div>

                      {/* <div className="d-flex my-4">
                    <a href="#" className="btn btn-sm btn-light me-2" id="kt_user_follow_button">
                      <KTIcon iconName="check" className="fs-3 d-none" />

                      <span className="indicator-label">Follow</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </a>
                    <a
                      href="#"
                      className="btn btn-sm btn-primary me-3"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_offer_a_deal">
                      Hire Me
                    </a>
                    <div className="me-0">
                      <button
                        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="top-end">
                        <i className="bi bi-three-dots fs-3"></i>
                      </button>
                      <Dropdown1 />
                    </div>
                  </div> */}
                    </div>

                    {fanlink && (
                      <div className="mb-4">
                        <a href={fanlink.url} className="btn btn-sm btn-dark" target="_blank">
                          fanlink
                        </a>
                      </div>
                    )}

                    {storeLinks && storeLinks.results.length > 0 && (
                      <div className="mb-4">
                        <StoreLinkButtons storeLinks={storeLinks.results}></StoreLinkButtons>
                      </div>
                    )}

                    {/* <div className="d-flex flex-wrap flex-stack">
                      <div className="d-flex flex-column flex-grow-1 pe-8">
                        <div className="d-flex flex-wrap">
                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <KTIcon iconName="arrow-up" className="fs-3 text-success me-2" />
                              <div className="fs-2 fw-bolder">4500$</div>
                            </div>

                            <div className="fw-bold fs-6 text-gray-500">Earnings</div>
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <KTIcon iconName="arrow-down" className="fs-3 text-danger me-2" />
                              <div className="fs-2 fw-bolder">75</div>
                            </div>

                            <div className="fw-bold fs-6 text-gray-500">Projects</div>
                          </div>

                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                            <div className="d-flex align-items-center">
                              <KTIcon iconName="arrow-up" className="fs-3 text-success me-2" />
                              <div className="fs-2 fw-bolder">60%</div>
                            </div>

                            <div className="fw-bold fs-6 text-gray-500">Success Rate</div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                          <span className="fw-bold fs-6 text-gray-500">Profile Compleation</span>
                          <span className="fw-bolder fs-6">50%</span>
                        </div>
                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                          <div
                            className="bg-success rounded h-5px"
                            role="progressbar"
                            style={{ width: '50%' }}></div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="d-flex overflow-auto h-55px">
                  <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                    <li className="nav-item">
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname ===
                            '/manager/catalog/releases/' + release?.id + '/overview' && 'active')
                        }
                        to={'/manager/catalog/releases/' + release?.id + '/overview'}>
                        Overview
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname ===
                            '/manager/catalog/releases/' + release?.id + '/informations' &&
                            'active')
                        }
                        to={'/manager/catalog/releases/' + release?.id + '/informations'}>
                        Informations
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname ===
                            '/manager/catalog/releases/' + release?.id + '/tracks' && 'active')
                        }
                        to={'/manager/catalog/releases/' + release?.id + '/tracks'}>
                        Tracks
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname ===
                        '/manager/catalog/releases/' + release?.id + '/accounting' && 'active')
                    }
                    to={'/manager/catalog/releases/' + release?.id + '/accounting'}>
                    Accounting
                  </Link>
                </li> */}
                    <li className="nav-item">
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname ===
                            '/manager/catalog/releases/' + release?.id + '/fanlink' && 'active')
                        }
                        to={'/manager/catalog/releases/' + release?.id + '/fanlink'}>
                        Fanlink configuration
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          `nav-link text-active-primary me-6 ` +
                          (location.pathname ===
                            '/manager/catalog/releases/' + release?.id + '/files' && 'active')
                        }
                        to={'/manager/catalog/releases/' + release?.id + '/files'}>
                        File manager
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname ===
                        '/manager/catalog/releases/' + release?.id + '/marketing' && 'active')
                    }
                    to={'/manager/catalog/releases/' + release?.id + '/marketing'}>
                    Marketing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname ===
                        '/manager/catalog/releases/' + release?.id + '/files' && 'active')
                    }
                    to={'/manager/catalog/releases/' + release?.id + '/files'}>
                    File manager
                  </Link>
                </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <Outlet />
          </>
        )) || <LoadingReleaseHeader />}
      </Content>
    </>
  );
};

export default ViewReleaseWrapper;
