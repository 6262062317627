import { StreamingPlatforms } from '../../../generics/_models';

export const getPlatfomData = (
  platform: string
): {
  name: string;
  icon: string;
  color: string;
  logoWhite: string;
  logoBlack: string;
  actionText: string;
  actionIcon: string;
} | null => {
  // get the right logo from StreamingPlatforms
  for (const [key, value] of Object.entries(StreamingPlatforms)) {
    if (key === platform) {
      return value;
    }
  }
  return null;
};

export function removeEmpty(obj: object) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== ''));
}
