import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, Routes } from 'react-router-dom';
import { WaveSurfer, WaveForm, Region, Marker } from 'wavesurfer-react';

import './audio_player.scss';
import { KTIcon } from '../../../_metronic/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

export function AudioPlayer(props: { audio_url: string; id: string }) {
  const wavesurferRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const plugins = useMemo(() => {
    return [];
  }, []);

  const colors = useMemo(() => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // Define the waveform gradient
    const gradient = ctx!.createLinearGradient(0, 0, 0, canvas.height * 1.35);
    gradient.addColorStop(0, '#656666'); // Top color
    gradient.addColorStop((canvas.height * 0.7) / canvas.height, '#656666'); // Top color
    gradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff'); // White line
    gradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff'); // White line
    gradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, '#B1B1B1'); // Bottom color
    gradient.addColorStop(1, '#B1B1B1'); // Bottom color

    // Define the progress gradient
    const progressGradient = ctx!.createLinearGradient(0, 0, 0, canvas.height * 1.35);
    progressGradient.addColorStop(0, '#EE772F'); // Top color
    progressGradient.addColorStop((canvas.height * 0.7) / canvas.height, '#EB4926'); // Top color
    progressGradient.addColorStop((canvas.height * 0.7 + 1) / canvas.height, '#ffffff'); // White line
    progressGradient.addColorStop((canvas.height * 0.7 + 2) / canvas.height, '#ffffff'); // White line
    progressGradient.addColorStop((canvas.height * 0.7 + 3) / canvas.height, '#F6B094'); // Bottom color
    progressGradient.addColorStop(1, '#F6B094'); // Bottom color

    return {
      waveColor: gradient,
      progressColor: progressGradient,
      cursorColor: 'white'
    };
  }, []);

  const handleWSMount = useCallback((waveSurfer: any) => {
    wavesurferRef.current = waveSurfer;

    if (wavesurferRef.current) {
      // @ts-ignore-next-line
      wavesurferRef.current.load(props.audio_url);

      // @ts-ignore-next-line
      wavesurferRef.current.on('ready', () => {
        console.log('WaveSurfer is ready');
        setIsLoaded(true);
      });

      const hover = document.querySelector('#hover');
      const waveform = document.querySelector('#waveform');

      if (waveform && hover) {
        // @ts-expect-error
        waveform.addEventListener('pointermove', (e) => (hover.style.width = `${e.offsetX}px`));
      }

      const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secondsRemainder = Math.round(seconds) % 60;
        const paddedSeconds = `0${secondsRemainder}`.slice(-2);
        return `${minutes}:${paddedSeconds}`;
      };

      const timeEl = document.querySelector('#time');
      const durationEl = document.querySelector('#duration');

      if (timeEl && durationEl) {
        // @ts-ignore-next-line
        wavesurferRef.current.on(
          'decode',
          (duration: number) => (durationEl.textContent = formatTime(duration))
        );

        // @ts-ignore-next-line
        wavesurferRef.current.on(
          'timeupdate',
          (currentTime: number) => (timeEl.textContent = formatTime(currentTime))
        );
      }

      // @ts-ignore-next-line
      wavesurferRef.current.on('play', () => {
        setIsPlaying(true);
      });

      // @ts-ignore-next-line
      wavesurferRef.current.on('pause', () => {
        setIsPlaying(false);
      });

      // @ts-ignore-next-line
      wavesurferRef.current.on('finish', () => {
        setIsPlaying(false);
      });
    }
  }, []);

  function playPause() {
    return () => {
      if (wavesurferRef.current) {
        // @ts-ignore-next-line
        wavesurferRef.current.playPause();
      }
    };
  }

  return (
    <>
      <div className="waveform-container">
        <div className="playButton" onClick={playPause()}>
          {isPlaying ? <FontAwesomeIcon icon={faPause} /> : <FontAwesomeIcon icon={faPlay} />}
        </div>
        <WaveSurfer
          plugins={plugins}
          barWidth={2}
          height={90}
          onMount={handleWSMount}
          waveColor={colors.waveColor}
          progressColor={colors.progressColor}
          cursorColor={colors.cursorColor}
          barHeight={1}
          container="#waveform">
          <WaveForm id="waveform">
            <div id="time">0:00</div>
            <div id="duration">0:00</div>
            <div id="hover"></div>
          </WaveForm>
        </WaveSurfer>
      </div>
    </>
  );
}
